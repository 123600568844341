import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../api/api';
import CarAddComponent from '../components/Cars/CarAdd';

const get_car_models = async () => {
    const response = await api.get('/car-model/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const get_staffs = async (params = {}) => {
    const response = await api.get('/account/', { params: params })
    if (response.data.success) {
        return response.data.data
    } else {
        return []
    }
}


const CarAdd = () => {
    let location = useLocation()
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [staffs, setStaffs] = useState([])
    useEffect(() => {
        async function get_car_models_fetch() {
            let response = await get_car_models()
            setModels(response)
        }
        get_car_models_fetch();
    }, []);
    useEffect(() => {
        async function get_staffs_fetch() {
            let res = await get_staffs({ select: true })
            console.log(res);
            setStaffs(res)
        }
        get_staffs_fetch()
    }, [])

    const add_car = async (data, clear) => {

        if (loading === false) {
            const response = await api.post("/car/", data)
            setLoading(true)
            if (response.data.success === true) {
                Swal.fire({
                    title: 'Success!',
                    text: "Mashina qo'shildi!",
                    icon: 'success'
                });
                clear();
                setLoading(false)
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: "Mashina qo'shishda xatolik mavjud!",
                    icon: 'error'
                });
                setLoading(false)
            }
        }

    }

    return (
        <CarAddComponent
            add_car={add_car}
            models={models}
            staffs={staffs}
            nav_page={location?.state?.page}
        />
    );
}

export default CarAdd;