import React, { useState } from 'react';
import { Button, Input } from '../../../Drivers/Tabs/PaymentTab/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../../Table/styles';
import { Container, Form } from '../Car/styles';



const PaymentTypePanel = ({paymentTypes, add_payment_types}) =>{
    const [name, setName] = useState("");
    const AddPaymentType = (e) =>{
        add_payment_types(name)
        setName("")
        e.preventDefault();
    }
    return(
        <Container>
            <TableContainer>
            <Table>
                <TableHead>
                    <TR>
                        <TH>#</TH>
                        <TH>To'lov turi</TH>
                    </TR>
                </TableHead>
                <TableBody>
                    {paymentTypes.map((dat, index)=>(
                        <TR>
                            <TD>{index+1}</TD>
                            <TD>{dat.name}</TD>
                        </TR>
                    ))}
                    
                </TableBody>
            </Table>
        </TableContainer>
            <Form onSubmit={AddPaymentType}>
                <Input type="text" placeholder="To'lov turi" required
                    onChange={(e)=>setName(e.target.value)} value={name}
                />
                <Button>Qo'shish</Button>
            </Form>
        </Container>
    );
}

export default PaymentTypePanel;