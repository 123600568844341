import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
`;
export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
`;
export const Title = styled.span`
    font-size: 16px;
    font-family: "Inter";
`;

export const Table = styled.table`
    border: 1px solid #666;
    border-collapse: collapse;
    margin: 5px;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const TH = styled.th`
    text-align: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #22242C;
    padding: 7px;
    border: 1px solid #666;
    text-align: center;
    
`;

export const TR = styled.tr`
    
`;

export const TD = styled.td`
    text-align: left;
    padding: 7px;
    font-family: "Inter";
    font-style: normal;
    font-size: 14px;
    line-height: 15px;
    color: #22242C;
    height: 34px;
    border: 1px solid #666;
    text-align: center;
`;



