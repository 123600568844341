import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Oylik tushum grafigi',
    },
  },
};

const labels = ['Yanvar', 'Febral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul', "Avg'ust", "Sentiyabr", "Oktyabr", "Noyabr", "Dekabr"];



const Container = styled.div`
    position: "relative";
    display: flex;
    background-color: white;
    min-height: 450px;
    max-height: 450px;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px #8E8EA1;
    padding: 10px;
`;

const Chart = ({ chart_data }) => {

  const [ch_data, setCh_data] = useState([])

  const data = {
    labels,
    datasets: [
      {
        label: 'Summa',
        data: ch_data,
        backgroundColor: '#54C5EB',
      }
    ],
  };

  useEffect(() => {
    setCh_data(chart_data)
    console.log(chart_data);
  }, [chart_data])



  return (
    <Container>
      <Bar options={options} data={data} height={70} width={"50%"} />
    </Container>
  )


}

export default Chart;