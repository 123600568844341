import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../api/api';
import CarEditComponent from '../components/Cars/CarEdit';

const get_car_models = async () => {

    const response = await api.get('/car-model/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const get_staffs = async (params = {}) => {
    const response = await api.get('/account/', { params: params })
    if (response.data.success) {
        return response.data.data
    } else {
        return []
    }
}


const get_car = async (id) => {
    const response = await api.get(`/car-edit/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return null
    }
}


const CarEdit = () => {
    let location = useLocation()
    const { car_id } = useParams()
    const [models, setModels] = useState([]);
    const [car, setCar] = useState({});
    const [staffs, setStaffs] = useState([])

    useEffect(() => {
        async function get_staffs_fetch() {
            let res = await get_staffs({ select: true })
            console.log(res);
            setStaffs(res)
        }
        get_staffs_fetch()
    }, [])

    useEffect(() => {
        async function get_car_models_fetch() {
            let response = await get_car_models()
            setModels(response)
        }
        get_car_models_fetch();
    }, []);

    useEffect(() => {
        async function get_car_fetch() {
            let response = await get_car(car_id)
            setCar(response)
        }
        get_car_fetch();
    }, [car_id]);

    const add_car = async (data, setcar) => {
        const response = await api.post(`/car-edit/${car_id}/`, data)
        if (response.data.success === true) {
            setCar(response.data.data)
            Swal.fire({
                title: 'Success!',
                text: "Mashina Tahrirlandi!",
                icon: 'success'
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Mashina tahrirlashda xatolik mavjud!",
                icon: 'error'
            });
        }
    }

    const add_let = async (data, clear_let) => {
        const response = await api.post(`/add-let/${car_id}/`, data)
        if (response.data.success === true) {
            setCar(response.data.data)
            Swal.fire({
                title: 'Success!',
                text: "Litsenziya yangilandi!",
                icon: 'success'
            });
            clear_let()
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Litsenziya yangilashda xatolik mavjud!",
                icon: 'error'
            });
        }
    }

    return (
        <CarEditComponent
            add_car={add_car}
            models={models}
            car={car}
            add_let={add_let}
            staffs={staffs}
            nav_page={location?.state?.page} />
    );
}

export default CarEdit;