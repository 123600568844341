import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import api from '../api/api';
import SettingsComponent from '../components/Settings';

const get_car_models = async () => {
    const response = await api.get('/car-model/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const get_payment_types = async () => {
    const response = await api.get('/payment-type/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const get_doromad_soligi = async () => {
    const response = await api.get('/daromad-soligi/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {}
    }
}



const Settings = () => {
    const [carModels, setCarModels] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [solig, setSolig] = useState({});
    useEffect(() => {
        async function get_car_models_fetch() {
            let response = await get_car_models()
            setCarModels(response)
        }
        get_car_models_fetch();
    }, []);
    useEffect(() => {
        async function get_payment_types_fetch() {
            let response = await get_payment_types()
            setPaymentTypes(response)
        }
        get_payment_types_fetch()
    }, []);

    useEffect(() => {
        async function get_doromad_soligi_fetch() {
            let response = await get_doromad_soligi()
            setSolig(response)
        }
        get_doromad_soligi_fetch()
    }, []);
    const add_car_model = async (name) => {
        if (loading === false) {
            const response = await api.post('/car-model/', {
                name: name
            })
            setLoading(true)
            if (response.data.success === true) {
                setCarModels([response.data.data, ...carModels])
                setLoading(false)
            } else (
                setLoading(false)
            )
        }


    }
    const add_payment_types = async (name) => {
        if (loading === false) {
            const response = await api.post('/payment-type/', {
                name: name
            })
            setLoading(true)
            if (response.data.success === true) {
                setPaymentTypes([response.data.data, ...paymentTypes])

                setLoading(false)
            } else {
                setLoading(false)

            }

        }
    }
    const add_solig = async (summa) => {
        if (loading === false) {
            const response = await api.post('/daromad-soligi/', {
                summa: summa
            })
            setLoading(true)
            if (response.data.success === true) {
                setSolig(response.data.data)
                Swal.fire({
                    title: 'Success!',
                    text: "Daromad solig'i o'zgartirildi!",
                    icon: 'success'
                });
                setLoading(false)
            } else {
                setLoading(false)
                Swal.fire({
                    title: 'Error!',
                    text: "Daromad solig'ini o'zgartirishda xatolik mavjud!",
                    icon: 'error'
                });
            }

        }
    }
    return (
        <SettingsComponent
            car_models={carModels}
            paymentTypes={paymentTypes}
            add_payment_types={add_payment_types}
            add_car_model={add_car_model}
            solig={solig}
            add_solig={add_solig}
        />

    )
}


export default Settings;