import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { DateInput } from '../Balance/Payments/styles';
import { DriverContainer, Search, Title, TitleContainer } from '../Cars/CarList/styles';
import { Button, FormItem, Input, Label } from '../Drivers/DriverAdd/styles';
import { Form, SearchSelect } from '../Drivers/Shartnoma/styles';
import PaginationComponent from '../Paginations';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../Table/styles';
import { Container } from './styles'

const LitsenziyaComponent = ({ cars, add_let, carLets, pagination, loadCarsLets }) => {
    const [car, setCar] = useState(null)
    const [raqam, setRaqam] = useState("");
    const [let_date_start, setLetDate_start] = useState("");
    const [let_date_end, setLetDate_end] = useState("");
    const [date_start, setDate_start] = useState(null);
    const [date_end, setDate_end] = useState(null);
    const [search, setSearch] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)

    const clear_let = () => {
        setCar(null)
        setRaqam("");
        setLetDate_start("");
        setLetDate_end("");
    }

    const add_let_submit = (e) => {
        if (car !== null) {
            const let_data = {
                raqam: raqam, let_date_start: let_date_start, let_date_end: let_date_end
            }
            add_let(let_data, clear_let, car?.value)
        } else {
            Swal.fire({
                title: 'Error!',
                text: "Mashina tanlanmadi!",
                icon: 'error'
            });
        }

        e.preventDefault();
    }

    const DateEndFilter = (value) => {
        setDate_end(value)
        if (date_start !== null) {
            loadCarsLets({
                search: search,
                date_end: value,
                date_start: date_start
            })
        }
    }
    const DateStartFilter = (value) => {
        setDate_start(value)
        if (date_end !== null) {
            loadCarsLets({
                search: search,
                date_end: date_end,
                date_start: value
            })
        }
    }
    const SearchFilter = (value) => {
        setSearch(value)
        if (date_start !== null && date_end !== null) {
            loadCarsLets({
                search: value,
                date_end: date_end,
                date_start: date_start
            })
        } else {
            loadCarsLets({
                search: value
            })
        }
    }

    const loadData = page => {
        setCurrentPage(page)
        loadCarsLets({
            search: search,
            date_end: date_end,
            date_start: date_start,
            page: page
        })
    }

    return (
        <Container>
            <Form onSubmit={(e) => add_let_submit(e)}>
                <FormItem>
                    <Label>Litsenziya qo'shish</Label>
                    <SearchSelect
                        value={car}
                        options={cars}
                        onChange={setCar}
                    />
                </FormItem>
                <FormItem>
                    <Label>Litsenziya Raqami</Label>
                    <Input type="text" placeholder='Litsenziya Raqami' onChange={(e) => setRaqam(e.target.value)} value={raqam} required />
                </FormItem>
                <FormItem>
                    <Label>Litsenziya boshlanish sana</Label>
                    <Input type="date" onChange={(e) => setLetDate_start(e.target.value)} value={let_date_start} required />
                </FormItem>
                <FormItem>
                    <Label>Litsenziya tugash sana</Label>
                    <Input type="date" onChange={(e) => setLetDate_end(e.target.value)} value={let_date_end} required />
                </FormItem>
                <FormItem>
                    <Label></Label>
                    <Button>Qo'shish</Button>
                </FormItem>
            </Form>
            <TitleContainer>
                <Title>To'lovlar</Title>
                <Search placeholder='Qidirish ... ' onChange={(e) => SearchFilter(e.target.value)} />
                <DateInput type="date" onChange={(e) => DateStartFilter(e.target.value)} />
                <DateInput type="date" onChange={(e) => DateEndFilter(e.target.value)} />
            </TitleContainer>
            <DriverContainer>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TR>
                                <TH>№</TH>
                                <TH>Mashina rusumi</TH>
                                <TH>Davlat raqami</TH>
                                <TH>Haydovchi</TH>
                                <TH>Inspektor</TH>
                                <TH>Qarz</TH>
                                <TH>Litsenziya</TH>
                            </TR>
                        </TableHead>
                        <TableBody>
                            {carLets?.map((dat, index) => (
                                <TR key={index}>
                                    <TD>{index + (pagination?.current_page - 1) * pagination.number_of_object + 1}</TD>
                                    <TD>{dat.model}</TD>
                                    <TD>{dat.number}</TD>
                                    <TD>{dat.driver}</TD>
                                    <TD>{dat.inspektor}</TD>
                                    <TD>{dat.credit}</TD>
                                    <TD>{dat.let_date_end}</TD>

                                </TR>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </DriverContainer>
            {pagination?.pages_count > 1 ?
                <PaginationComponent
                    activePage={pagination.current_page}
                    items_count={pagination.items_count}
                    range_display={5}
                    loadData={loadData}
                    items_per_page={pagination.number_of_object}
                /> : null}
        </Container>
    )
}

export default LitsenziyaComponent;