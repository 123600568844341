import React, { useEffect, useState } from 'react';
import { Container, DriverContainer, TitleContainer, Title, AddButton, Search, DetailBtn, StatusSpan } from './styles';
import * as ROUTES from "../../../Constants/routes";
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../Table/styles';
import { FaEdit, FaEye, FaTrash, FaFile, FaAngleDown, FaAngleUp, FaFileExcel } from 'react-icons/fa';
import { get_permission } from '../../../Constants/get_permission';
import PaginationComponent from '../../Paginations';
import { BtnContainer } from '../../Cars/CarList/styles';
import CarDeleteModal from '../../Cars/DeleteModal';
import Swal from 'sweetalert2';
import api from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import { Select } from '../../Cars/CarEdit/styles';
import { ExportBtn } from '../../Balance/Payments/styles';

const get_staffs = async (params = {}) => {
    const response = await api.get('/account/', { params: params })
    if (response.data.success) {
        return response.data.data
    } else {
        return []
    }
}

const DriversComponent = ({ pagination, setPagination, drivers, setDrivers, search_driver }) => {
    const [permission, setPermission] = useState([])
    const [delete_id, setDelete_id] = useState(null)
    const [isopenD, setIsOpenD] = useState(false);
    const [currentPage, setCurrentPage] = useState(pagination.current_page)
    const [search_text, setSearch_text] = useState("")
    const [filter_n, setFilter_n] = useState(null)
    const [filter_c, setFilter_c] = useState(null)
    const [staffs, setStaffs] = useState([])
    const [inspektor, setInspektor] = useState(0);

    let navigate = useNavigate();
    const search = (e) => {
        setSearch_text(e.target.value)
        const params = {
            search: e.target.value,
            is_active: true
        }
        search_driver(params);
    }

    useEffect(() => {
        async function get_staffs_fetch() {
            let res = await get_staffs({ select: true })
            console.log(res);
            setStaffs(res)
        }
        get_staffs_fetch()
    }, [])
    const delete_car = async (car_id, comment) => {
        setIsOpenD(false)
        const response = await api.put(`/driver-edit/${car_id}/`, { comment: comment, method: "delete" })
        if (response.data.success === true) {
            setIsOpenD(false)
            Swal.fire({
                title: 'Success!',
                text: "Haydovchi o'chirildi!",
                icon: 'success'
            });

            search_driver({
                search: search_text,
                page: currentPage,
                is_active: true
            })
        } else {

            Swal.fire({
                title: 'Error!',
                text: "Mashina o'chirishda xatolik mavjud!",
                icon: 'error'
            });
        }

    }

    const export_data = async () => {

        const response = await api.get('/export-driver/', { params: { inspektor: inspektor } })
        console.log(response.data)
        if (response.data.success === true) {
            window.open("https://backoffice.webtaxi.uz" + response.data.data)
        }
    }
    const loadData = page => {
        setCurrentPage(page)
        if (filter_c !== null) {
            if (inspektor === 0) {
                search_driver({ page: page, is_active: true, credit: filter_c })

            } else {
                search_driver({ page: page, is_active: true, credit: filter_c, inspektor: inspektor })

            }

        } else if (filter_n !== null) {

            if (inspektor === 0) {
                search_driver({ page: page, is_active: true, name: filter_n })

            } else {
                search_driver({ page: page, is_active: true, name: filter_n, inspektor: inspektor })

            }
        } else {

            if (inspektor === 0) {
                search_driver({ page: page, is_active: true })

            } else {
                search_driver({ page: page, is_active: true, inspektor: inspektor })

            }
        }

    }
    useEffect(() => {

        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])
    const open_delete = (id) => {
        setDelete_id(id);
        setIsOpenD(true)
    }
    useEffect(() => {
        setCurrentPage(pagination.current_page)
    }, [pagination])

    const click_credit = () => {
        setFilter_n(null)
        if (filter_c === true) {
            setFilter_c(false)
            search_driver({
                is_active: true,
                credit: false,
                inspektor: inspektor
            })
        } else if (filter_c === false) {
            setFilter_c(null)
            search_driver({
                is_active: true,
                inspektor: inspektor

            })
        } else if (filter_c === null) {
            setFilter_c(true)
            search_driver({
                is_active: true,
                credit: true,
                inspektor: inspektor

            })
        }
    }

    const click_name = () => {
        setFilter_c(null)
        if (filter_n === true) {
            setFilter_n(false)
            search_driver({
                is_active: true,
                name: false,
                inspektor: inspektor

            })
        } else if (filter_n === false) {
            setFilter_n(null)
            search_driver({
                is_active: true,
                inspektor: inspektor

            })
        } else if (filter_n === null) {
            setFilter_n(true)
            search_driver({
                is_active: true,
                name: true,
                inspektor: inspektor

            })
        }
    }

    const InspektorChange = (value) => {
        setInspektor(value)
        search_driver({ is_active: true, inspektor: value })
    }
    return (
        <Container>
            <TitleContainer>
                <Title>Haydovchilar</Title>
                <Select onChange={(e) => InspektorChange(e.target.value)} style={{ marginLeft: 'auto', marginRight: "150px", height: '32px' }}>
                    {inspektor === 0 ?
                        <option selected value={0}>Barcha Inspektorni</option> :
                        <option value={0}>Barcha Inspektorni</option>
                    }

                    {staffs?.map((md, index) => (
                        <option value={md.id} selected={md.id === inspektor ? "selected" : null}>{md.name}</option>
                    ))}

                </Select>
                <ExportBtn onClick={() => export_data()}><FaFileExcel /></ExportBtn>
                <Search placeholder='Qidirish ... ' onChange={(e) => search(e)} />
                {permission?.includes(1) ?
                    <AddButton to="#" onClick={() => navigate('/drivers-delete', { state: { page: currentPage } })}>O'chirilganlar</AddButton> : null}
                {permission?.includes(1) ?
                    <AddButton onClick={() => navigate(ROUTES.DRIVER_ADD, { state: { page: currentPage } })}>Qo'shish</AddButton> : null}
            </TitleContainer>
            <DriverContainer>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TR>
                                <TH>№</TH>
                                <TH style={{ cursor: "pointer" }} onClick={() => click_name()}>FIO {filter_n === true ? <FaAngleUp style={{ marginLeft: "15px" }} size={14} /> : filter_n === false ? <FaAngleDown style={{ marginLeft: "15px" }} size={14} /> : null}</TH>
                                <TH>Telefon</TH>
                                <TH>Inspektor</TH>
                                <TH>Mashina</TH>
                                <TH style={{ cursor: "pointer" }} onClick={() => click_credit()}>Qarz
                                    {filter_c === true ? <FaAngleUp style={{ marginLeft: "15px" }} size={14} /> : filter_c === false ? <FaAngleDown style={{ marginLeft: "15px" }} size={14} /> : null}</TH>
                                <TH>Shartnoma</TH>
                                {permission.includes(2) ? <TH></TH> : null}
                            </TR>
                        </TableHead>
                        <TableBody>
                            {drivers?.map((dat, index) => (
                                <TR key={index}>
                                    <TD>{index + (pagination?.current_page - 1) * pagination.number_of_object + 1}</TD>
                                    <TD>{dat.name}</TD>
                                    <TD>{dat.phone}</TD>
                                    <TD>{dat.inspektor}</TD>
                                    <TD>{dat.car}</TD>
                                    <TD>{dat.credit}</TD>
                                    <TD><DetailBtn onClick={() => navigate(`/shartnoma/${dat.id}/`)} key={index}><FaFile /></DetailBtn></TD>
                                    <TD>
                                        <BtnContainer key={index}>
                                            <DetailBtn bg="#4287f5" to="#" onClick={() => navigate(`/driver-detail/${dat.id}`, { state: { page: currentPage } })}><FaEye /></DetailBtn>
                                            {permission.includes(2) ? <DetailBtn bg="#f5c842" onClick={() => navigate(`/driver-edit/${dat.id}`, { state: { page: currentPage } })}><FaEdit /></DetailBtn> : null}
                                            {permission.includes(14) ? <DetailBtn bg="red" to={"#"} onClick={() => open_delete(dat.id)}><FaTrash /></DetailBtn> : null}
                                        </BtnContainer>
                                    </TD>

                                </TR>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </DriverContainer>
            <CarDeleteModal isOpen={isopenD} setIsOpen={setIsOpenD} DeleteCar={delete_car} car_id={delete_id} />
            <PaginationComponent
                activePage={pagination.current_page}
                items_count={pagination.items_count}
                range_display={5}
                loadData={loadData}
                items_per_page={pagination.number_of_object}
            />
        </Container>
    );
}

export default DriversComponent;