import React, { useEffect, useRef, useState } from 'react'
import { get_permission } from '../../../Constants/get_permission';
import { Input } from '../../Drivers/DriverAdd/styles';
import { CarTab } from '../../Drivers/Tabs';
import { Button, FormItem, Label } from '../CarAdd/styles';
import { Title } from '../CarList/styles';
import { Container, Form } from './styles';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BackBtn } from '../../Drivers/DriverEdit/styles';
import { useReactToPrint } from 'react-to-print';
import { ButtonContainer } from '../../Drivers/Tabs/Document/styles';
import { PrintButton } from '../../../pages/vedmost';
import { FaPrint, FaTrash } from "react-icons/fa";
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../Table/styles';
import PaginationComponent from '../../Paginations';
import { CheckDiv, CheckItem, QRDiv, Text, Title as TitleCheck } from '../../Drivers/Tabs/PaymentTab/styles';
import QRCode from 'react-qr-code';
import { DetailBtn } from '../../Drivers/DriverList/styles';
import Swal from 'sweetalert2';
import api from '../../../api/api';

const Print = React.forwardRef((props, ref) => {
    const date = new Date()
    return (
        <CheckDiv ref={ref}>
            <TitleCheck style={{ backgroundColor: "#999999", padding: "5px" }}>Web-taxi</TitleCheck>
            <TitleCheck>Квитанция: {props?.printPayment?.id}</TitleCheck>
            <CheckItem>
                <Text>Чоп этилган санаси:</Text>
                <Text>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Авто Русуми:</Text>
                <Text>{props.printPayment?.car?.model}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Авто Раками:</Text>
                <Text>{props.printPayment?.car?.number}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Тўлов суммаси:</Text>
                <Text>{props.printPayment.summa}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Тулов Максади: </Text>
                <Text>Даромад солиги</Text>
            </CheckItem>
            <CheckItem>
                <Text>Тўлов санаси:</Text>
                <Text>{props.printPayment.date}</Text>
            </CheckItem>
            <QRDiv>
                <TitleCheck style={{ fontSize: "15px", textAlign: "left", margin: "10px 0px" }}><i>Ёрдамингиз билан ривожланмоқдамиз!</i></TitleCheck>
                <QRCode value={String(props.printPayment.id)} size={64} style={{ marginLeft: "10px" }} />
            </QRDiv>

        </CheckDiv>
    )
});

const CarPaymentComponent = ({ car_payments, add_car_ijara, nav_page, car, loadData }) => {
    let navigate = useNavigate();
    const [summa, setSumma] = useState("");
    const [permission, setPermission] = useState([])
    const [printPayment, setPrintPayment] = useState({});
    const [printOpen, setPrintOpen] = useState(false)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])

    const PrintPaymentHandle = (data) => {
        setPrintPayment("")
        setPrintPayment(data)
        setPrintOpen(true)
    }

    useEffect(() => {
        if (printOpen === true) {
            handlePrint()
            setPrintOpen(false)
        }

    }, [printPayment])

    const add = (e) => {
        add_car_ijara({
            summa: summa,
        }, car.id)
        setSumma("");
        e.preventDefault();
    }
    const return_back = () => {
        navigate('/cars', { state: { page: nav_page } })
    }


    const delete_pay = async (id) => {
        const response = await api.delete(`/delete-solig/${id}/`)
        if (response.data.success === true) {
            loadData(1)
        } else {

            Swal.fire({
                title: 'Error!',
                text: "To'lov o'chirishda xatolik mavjud!",
                icon: 'error'
            });
        }

    }

    const open_delete = (id) => {
        Swal.fire({
            title: 'Info!',
            text: "To'lovni o'chirishni hohlesizmi!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonText: 'Ha',
            cancelButtonText: "Yo'q"
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                delete_pay(id)
            }
        })
    }

    return (
        <Container>
            <BackBtn onClick={return_back}><FaLongArrowAltLeft /></BackBtn>
            <Title>Daromad soligini to'lash ({car?.number})</Title>
            {permission?.includes(6) ?
                <Form onSubmit={add}>
                    <FormItem>
                        <Label>Daromad Soligini to'lash</Label>
                        <Input type="number" placeholder='Summa' onChange={(e) => setSumma(e.target.value)}
                            value={summa} required />
                    </FormItem>
                    <FormItem>
                        <Label></Label>
                        <Button style={{ marginBottom: "0px", width: "150px" }}>To'lash</Button>
                    </FormItem>

                </Form> : null}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TR>
                            <TH>№</TH>
                            <TH>Mashina</TH>
                            <TH>Summa</TH>
                            <TH>Sana</TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {car_payments?.result?.map((dat, index) => (
                            <TR>
                                <TD>{index + 1}</TD>
                                <TD>{dat?.car?.number}</TD>
                                <TD>{dat.summa}</TD>
                                <TD>{dat.date}</TD>
                                {permission?.includes(21) ? <TD><DetailBtn bg="#f5424b" to="#" onClick={() => open_delete(dat.id)}><FaTrash /></DetailBtn></TD> : null}
                                <TD><PrintButton bg="#4287f5" onClick={() => PrintPaymentHandle(dat)} key={index}>
                                    <FaPrint size={18} />
                                </PrintButton></TD>
                            </TR>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationComponent
                activePage={car_payments?.pagination?.current_page}
                items_count={car_payments?.pagination?.items_count}
                range_display={5}
                loadData={loadData}
                items_per_page={car_payments?.pagination?.number_of_object}
            />
            <div style={{ display: "none" }}>
                <Print ref={componentRef} printPayment={printPayment} />

            </div>
        </Container>
    );
}

export default CarPaymentComponent;