import styled from "styled-components";

export const DateInput = styled.input`
    height: 32px;
    width: 150px;
    border: none;
    border-bottom:1px solid #999;
    margin-right: 15px;
    margin-left: 15px;
    outline: none;
    font-family: "Inter";
    font-size: 15px;
    
`;