import React from 'react'
import Modal from "react-modal";
import "./styles.css";
import HashLoader from "react-spinners/HashLoader";
import styled from 'styled-components';
Modal.setAppElement("#root")

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 200px;
`;
const Loader = ({ isOpen, setIsOpen }) => {
    function toggleModal() {
        setIsOpen(!isOpen);
    }
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
            shouldCloseOnEsc={false}
            bodyOpenClassName='mybody'
            shouldCloseOnOverlayClick={false}
            portalClassName='mybody'
        >
            <Container>
                <HashLoader
                    size={120}
                    color={'#F37A24'}
                    loading={isOpen}
                />
            </Container>

        </Modal>
    );
}
export default Loader;