import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import { Select } from '../../Cars/CarAdd/styles';
import { FormItem, Input, Label } from '../../Drivers/DriverAdd/styles';
import { Btn, BtnDiv, Container, Form, Text } from './styles';
import "./styles.css";

Modal.setAppElement("#root")


const PaymentEditModal = ({ isOpen, setIsOpen, EditPayment, payment, paymentTypes }) => {
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [type, setType] = useState(0);
  const [summa, setSumma] = useState("");
  const edit_payment = (e) => {
    EditPayment({ summa: summa, type: type })
  }
  useEffect(() => {
    setSumma(payment.summa)
    setType(payment.type)
  }, [payment])


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="mymodal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
    >
      <Container>
        <Form onSubmit={(e) => edit_payment(e)}>
          <FormItem style={{ paddingBottom: "10px" }}>
            {payment.status === 0 ?
              <Label>To'lov turi</Label> : null}
            {payment.status === 0 ?

              <Select style={{ paddingRight: "10px" }} onChange={(e) => setType(e.target.value)}>
                {type === 0 ?
                  <option selected disabled value={type}>To'lov turini tanlang</option> :
                  <option disabled value={type}>To'lov turini tanlang</option>
                }
                {paymentTypes?.map((py, index) => (
                  <option value={py.id} selected={payment.type === py.id ? "selected" : null}>{py.name}</option>
                ))}
              </Select> : null}
          </FormItem>
          <FormItem style={{ paddingBottom: "10px" }}>
            <Label>Summa</Label>
            <Input type="number" placeholder='Summa' value={summa} onChange={(e) => setSumma(e.target.value)} />
          </FormItem>
          <BtnDiv>
            <Btn>Tahrirlash</Btn>
            <Btn bg="#e61c1c" onClick={toggleModal} type="button">Yopish</Btn>
          </BtnDiv>
        </Form>

      </Container>
    </Modal>
  );
}
export default PaymentEditModal;