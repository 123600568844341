import React, { useEffect, useState } from 'react'
import Modal from "react-modal";
import { FormItem, Input, Label } from '../../Drivers/DriverAdd/styles';
import { Btn, BtnDiv, Container, Form, Text } from './styles';
import "./styles.css";

Modal.setAppElement("#root")


const CarDeleteModal = ({ isOpen, setIsOpen, DeleteCar, car_id }) => {
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [comment, setComment] = useState("");

  const SubmitHandle = (e) => {
    e.preventDefault();
    DeleteCar(car_id, comment)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="mymodal"
      overlayClassName="myoverlay"
      closeTimeoutMS={500}
    >
      <Container>
        <Form onSubmit={(e) => SubmitHandle(e)}>
          <FormItem style={{ paddingBottom: "10px" }}>
            <Label>Izoh</Label>
            <Input type="text" placeholder='Izoh' value={comment} onChange={(e) => setComment(e.target.value)} required />
          </FormItem>
          <BtnDiv>
            <Btn bg="red">O'chirish</Btn>
            <Btn bg="black" onClick={toggleModal} type="button">Yopish</Btn>
          </BtnDiv>
        </Form>

      </Container>
    </Modal>
  );
}
export default CarDeleteModal;