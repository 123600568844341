import React, { useEffect, useState } from 'react';
import { Button, Input } from '../../../Drivers/Tabs/PaymentTab/styles';
import { Container, Form } from '../Car/styles';



const DoromadSoligiPanel = ({ solig, add_solig }) => {
    const [summa, setSumma] = useState("");
    const AddPaymentType = (e) => {
        add_solig(summa)
        setSumma("")
        e.preventDefault();
    }
    useEffect(() => {
        setSumma(solig.summa)
    }, [solig])
    return (
        <Container>
            <Form onSubmit={AddPaymentType}>
                <Input type="number" placeholder="Summa" required
                    onChange={(e) => setSumma(e.target.value)} value={summa}
                />
                <Button>O'zgartirish</Button>
            </Form>
        </Container>
    );
}

export default DoromadSoligiPanel;