import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { Container, HeaderContainer, ItemContainer, SubText, Title, TitleContainer, DetailContainer } from '../CarTab/styles';
import { ButtonContainer, PrintButton } from '../Document/styles';
import { FaPrint } from "react-icons/fa";
const DriverTab = ({ driver }) => {
    const pathRef = useRef();
    const handlePathPrint = useReactToPrint({
        content: () => pathRef.current,
    });
    return (
        <Container>
            <ButtonContainer>
                <PrintButton bg="#0d9445" onClick={handlePathPrint}>
                    <FaPrint size={24} style={{ paddingRight: "10px" }} />
                </PrintButton>
            </ButtonContainer>
            <div ref={pathRef} style={{ padding: "40px" }}>
                <HeaderContainer>
                    <TitleContainer>
                        <Title size="24px">WEB TAXI</Title>
                        <SubText size="13px">Dream Gold Andijon MCHJ</SubText>
                    </TitleContainer>
                    <TitleContainer style={{ marginLeft: "auto" }}>
                        <Title>ID Ходим</Title>
                        <SubText size="15px">{driver.id}</SubText>
                    </TitleContainer>
                    <TitleContainer>
                        <Title>Yaratilgan sanasi</Title>
                        <SubText size="15px">{driver.created}</SubText>
                    </TitleContainer>
                </HeaderContainer>
                <DetailContainer>
                    <Title size="18px" style={{ textAlign: "center" }}>Хайдовчи шахсий варакаси № {driver.id}</Title>
                    <ItemContainer>
                        <Title size="16px">Ф.И.Ш:</Title>
                        <SubText size="16px">{driver.name}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">Яшаш манзили :</Title>
                        <SubText size="16px">{driver.address}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">Тугулган йили :</Title>
                        <SubText size="16px">{driver.birthdate}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">СТИР :</Title>
                        <SubText size="16px">{driver.stir}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">ЖЩШИР :</Title>
                        <SubText size="16px">{driver.jshshir}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">Паспорт раками :</Title>
                        <SubText size="16px">{driver.passport}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">Ким томонидан берилган :</Title>
                        <SubText size="16px">{driver.kim_tomonidan}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">Миллати: :</Title>
                        <SubText size="16px">{driver.millati}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">Маълумоти: :</Title>
                        <SubText size="16px">{driver.deegre}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">Телефон раками :</Title>
                        <SubText size="16px">{driver.phone}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">Пластик раками :</Title>
                        <SubText size="16px">{driver.credit_car}</SubText>
                    </ItemContainer>
                    <ItemContainer>
                        <Title size="16px">Автомобил:</Title>
                        <SubText size="16px">{driver.car}</SubText>
                    </ItemContainer>
                </DetailContainer>
            </div>

        </Container>
    );
}

export default DriverTab;