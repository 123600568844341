import React, { useEffect, useRef, useState } from 'react'
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { Container } from '../CarTab/styles';
import ContractComponent from './contract';
import { ButtonContainer, PrintButton } from '../Document/styles';
import api from '../../../../api/api';


const get_shartnoma = async (id) => {
    const response = await api.get(`/get-shartnoma/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}


const Contracts = ({ driver_id }) => {
    const [contract, setContract] = useState([])
    const contractRef = useRef();
    const handleContractPrint = useReactToPrint({
        content: () => contractRef.current,
    });
    useEffect(() => {
        async function get_shartnoma_fetch() {
            let response = await get_shartnoma(driver_id)
            setContract(response)
        }
        get_shartnoma_fetch();
    }, [driver_id]);
    return (
        <Container>
            <ButtonContainer>
                <PrintButton bg="#4287f5"
                    onClick={handleContractPrint}
                >
                    <FaPrint size={24} style={{ paddingRight: "10px" }} />
                    Shartnoma
                </PrintButton>
            </ButtonContainer>
            <ContractComponent ref={contractRef} contract={contract} />
        </Container>
    );
}

export default Contracts;