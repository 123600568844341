import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    
`;

export const Form = styled.form`
    display: inline;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
`;

export const Sellect = styled.select`
    border: 1px solid #8E8EA1;
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 5px;
    font-family: "Inter";
    height: 36px;
    outline: none;
    min-width: 150px;
    :focus{
        border: 1px solid #03cafc;
    }
    border-radius: 8px;
`;

