import styled from "styled-components";
import Select from 'react-select';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    top: 0px;
    bottom: 0px;
    height: 100%;
`;

export const MessageContainer = styled.div`
    display: flex;
    bottom: 0px;
    margin: 20px;
    margin-top: auto;
    right: 0px;
    left: 0px;
    width: 100%;
    position: relative;
    grid-gap: 15px;
`;

export const SendBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border: none;
    cursor: pointer;
    background-color: #036ffc;
    margin-top: auto;
    border-radius: 7px;
    font-size: 15px;
    color: white;
    transition: all 0.4s;
    &:hover{
        opacity: 0.7;
    }
`;

export const MessageInput = styled.textarea`
    height: 200px;
    width: 400px;
    word-wrap: break-word;
    white-space: pre;
    border: none;
    outline: none;
    border-radius: 15px;
    padding: 15px;
    font-size: 15px;
    right: 0px;
    @media (max-width: 1400px){
        width: 300px;
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    grid-gap: 10px;
`;

export const TopContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DeleteBtn = styled.button`
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 5px;
    font-size: 12px;
    color: white;
    background-color: red;
    cursor: pointer;
    border-radius: 4px;
    border: none;
`;

export const Title = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #22242C;
`;

export const Name = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #999999;
`;

export const CardContent = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto;
`;

export const Text = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
`;

export const TopFilter = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 20px;
    padding: 5px 15px;
`;

export const FilterBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #eb9b34;
    background-color: white;
    padding: 5px;
    border-radius: 7px;
`;

export const SearchSelect = styled(Select)`
    box-sizing: border-box;
    font-size: 16px;
    font-family: "Inter";
    height: 32px;
    outline: none;
    :focus{
        border: 1px solid #03cafc;
    }
    border-radius: 8px;
    input{
        @media (max-width:1400px){
            width: 300px !important;
        }
        
    }
`;

export const ExampleContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    margin: 15px;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
`;


export const LeftMidle = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 7px;
    overflow: hidden;
    padding: 20px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    
    &.max{
        transition: all .3s ease-in-out;
        height: 650px;
        padding: 20px;
        @media (max-width: 1400px){
            height: 478px;
        }
        overflow: auto;
        ::-webkit-scrollbar {
                 width:8px;
            }

            /* Track */
        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 4px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
        background: green; 
        border-radius: 4px;
        }
            
    }
    
    &.min{
        transition: all .3s ease-in-out;
        height: 0px;
        padding: 0px;   
        overflow: hidden;
    }
`;

export const LeftBottom = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #030233;
    height: 0%;
    overflow: hidden;
    grid-gap: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    &.max{
        transition: all .3s ease-in-out;
        height: 650px;
        padding: 20px;
        @media (max-width: 1400px){
            height: 478px;
        }
        overflow: auto;
        ::-webkit-scrollbar {
                 width:8px;
            }

            /* Track */
        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 4px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
        background: yellowgreen; 
        border-radius: 4px;
        }
            
    }
    &.min{
        transition: all .3s ease-in-out;
        height: 0px;
        padding: 0px;
    }
`;

export const SearchInput = styled.input`
    background-color: white;
    height: 32px;
    width: 100%;
    font-size: 16px;
    border-radius: 7px;
    outline: none;
    border: 1px solid #ceddde;
    padding-left: 10px;
    padding-right: 10px;
    
    :focus{
        border: 1px solid #38ecfc;
    }

`;

export const AddBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 35px;
    width: 40px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    margin-left: 15px;
    background-color: #29cde6;
    color: white;
    transition: all 0.4s;
    &:hover{
        transform: scale(1.02);
    }
`;

export const LeftTop = styled.div`
    display: flex;
    padding: 5px 15px;
    align-items: center;
`;
export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ceddde;
    border-top-left-radius: 7px;
    border-bottom-left-radius : 7px;
    height: 100%;
    top: 0px;
    bottom: 0px;
`;

export const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ceddde;
    border-top-right-radius: 7px;
    border-bottom-right-radius : 7px;
    height: 100%;
    top: 0px;
    bottom: 0px;
`;
export const Top = styled.div`
    display: flex;
    justify-content: space-between;

    padding: 10px;
    padding-right: 30px;
    align-items: center;
`;

export const Main = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 15px;
    height: 100%;
    top: 0px;
    bottom: 0px;
`;