import React, { useState } from "react";
import api from "../../api/api";
import { Button, Container, Form, Input, Label, LogoTitle, SignInDiv } from "./styles";
import Swal from 'sweetalert2'


const signInHandler = (e, username, password, setToken) => {
    api.get(`/login/?username=${username}&password=${password}`)
    .then(function (response) {
        if (response.data.success === true){
            var token = response.data.data.token
            localStorage.setItem("token", token)
            localStorage.setItem('account', response.data.data.account.id)
            setToken(token)
        }else{
            Swal.fire({  
                title: 'Error!',  
                text: 'Username yoki password xato!',
                icon: 'error'
              }); 
        }
      })
      .catch(function (error) {
        Swal.fire({  
            title: 'Error!',  
            text: 'Tizimda xatolik mavjud!',
            icon: 'error'
          });
      })
      .then(function () {
        // always executed
      });  
    e.preventDefault();
}

const SignIn = ({setToken}) =>{
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null)
    
    return (
        <Container>
            <SignInDiv>
                <LogoTitle><span style={{color:"black"}}>Web-</span>taxi</LogoTitle>
                <Form onSubmit={(e)=>signInHandler(e, username, password, setToken)}>
                    <Label>Username</Label>
                    <Input type="text" placeholder="Username"
                        onChange={(e)=>setUsername(e.target.value)}
                        required
                    />
                    <Label>Password</Label>
                    <Input type="password" placeholder="Password"
                        onChange={(e)=>setPassword(e.target.value)}
                        required
                    />
                    <Button type="submit" >Kirish</Button>
                </Form>
            </SignInDiv>
        </Container>
    );
}

export default SignIn;