import React from 'react'
import Modal from "react-modal";
import { Btn, BtnDiv, Container, Text } from './styles';
import "./styles.css";

Modal.setAppElement("#root")


const PaymentDeleteModal = ({isOpen, setIsOpen, DeletePayment}) =>{
    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <Container>
          <Text>To'lovni o'chirishni hohlaysizmi?</Text>
          <BtnDiv>
            <Btn onClick={()=>DeletePayment()}>Ha</Btn>
            <Btn bg="#e61c1c" onClick={toggleModal}>Yo'q</Btn>
          </BtnDiv>
        </Container>
      </Modal>
    );
}
export default PaymentDeleteModal;