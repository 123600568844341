import React, { useEffect, useState } from 'react';
import { Container, DriverContainer, TitleContainer, Title, AddButton, Search, BtnContainer } from './styles';
import { DetailBtn, StatusSpan } from '../../Drivers/DriverList/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../Table/styles';
import { FaEdit, FaCheck, FaTrash } from 'react-icons/fa';
import { get_permission } from '../../../Constants/get_permission';
import PaginationComponent from '../../Paginations';
import Swal from 'sweetalert2';
import api from '../../../api/api';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { BackBtn } from '../../Drivers/DriverEdit/styles';
const CarsDeleteComponent = ({ search_car, pagination, setPagination, cars, nav_page }) => {
    let navigate = useNavigate();
    const [permission, setPermission] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [search_text, setSearch_text] = useState("")
    const search = (e) => {
        setSearch_text(e.target.vale)
        const params = {
            search: e.target.value,
            is_active: "false"
        }
        search_car(params);
    }
    const loadData = page => {
        setCurrentPage(page)
        search_car({ page: page, is_active: "false" })
    }

    const update_car = async (car_id) => {
        const response = await api.put(`/car-edit/${car_id}/`, { method: "update" })
        if (response.data.success === true) {
            search_car({
                search: search_text,
                page: currentPage,
                is_active: false
            })
        } else {

            Swal.fire({
                title: 'Error!',
                text: "Mashina qayta tiklashda xatolik mavjud!",
                icon: 'error'
            });
        }

    }

    useEffect(() => {
        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])

    const return_back = () => {
        navigate('/cars', { state: { page: nav_page } })
    }

    const return_car = (id) => {
        Swal.fire({
            title: 'Info!',
            text: "Mashina qayta tiklashni hohlesizmi!",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Ha',
            cancelButtonText: "Yo'q"
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                update_car(id)
            }
        })
    }

    return (
        <Container>
            <BackBtn onClick={return_back}><FaLongArrowAltLeft /></BackBtn>
            <TitleContainer>
                <Title>O'chirilgan Mashinalar</Title>
                <Search placeholder='Qidirish ... ' onChange={(e) => search(e)} />
            </TitleContainer>
            <DriverContainer>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TR>
                                <TH>№</TH>
                                <TH>Mashina rusumi</TH>
                                <TH>Davlat raqami</TH>
                                <TH>Egasi</TH>
                                <TH>Qarzi</TH>
                                <TH>Litsenziya</TH>
                                <TH>Izoh</TH>
                                <TH></TH>
                            </TR>
                        </TableHead>
                        <TableBody>
                            {cars?.map((dat, index) => (
                                <TR key={index}>
                                    <TD>{index + (pagination?.current_page - 1) * pagination.number_of_object + 1}</TD>
                                    <TD>{dat.model}</TD>
                                    <TD>{dat.number}</TD>
                                    <TD>{dat.owner_name}</TD>
                                    <TD>{dat.credit}</TD>
                                    <TD>{dat.let_date_end}</TD>
                                    <TD>{dat.comment}</TD>
                                    <TD>
                                        <BtnContainer key={index}>
                                            {permission.includes(19) ? <DetailBtn bg="#4287f5" to="#" onClick={() => return_car(dat.id)}><FaCheck /></DetailBtn> : null}
                                        </BtnContainer>
                                    </TD>

                                </TR>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </DriverContainer>
            {pagination?.pages_count > 1 ?
                <PaginationComponent
                    activePage={pagination.current_page}
                    items_count={pagination.items_count}
                    range_display={5}
                    loadData={loadData}
                    items_per_page={pagination.number_of_object}
                /> : null}
        </Container>
    );
}

export default CarsDeleteComponent;