import styled from "styled-components";




export const Logo = styled.span`
    font-size: 16px;
    padding: 10px;
    font-weight: 600;
    background-color: #999;
    width: 200px;
    text-align: center;
`;

export const Text = styled.span`
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 600;
    letter-spacing: 3px;
    font-family: Cambria;
    
`;


export const SubText = styled.span`
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
    letter-spacing: 2px;
    font-weight: 400;
    font-family: Cambria;
`;

export const Div = styled.div`
    display: grid;
    grid-template-columns: 80% 20%;
`;

export const DivFirst = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DivSecond = styled.div`
    display: flex;
    flex-direction: column;
`;


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    visibility: none;
    grid-gap: 20px;
    @media print{
        @page {
            size: a4 landscape;
            margin: 30px;
        }
        ${DivFirst}{
            max-width: 148mm;
            max-height: 210mm;
            min-width: 148mm;
            max-height: 210mm;
            position: relative;
            zoom: 0.83;
        }
        ${DivSecond}{
            max-width: 148mm;
            max-height: 210mm;
            min-width: 148mm;
            max-height: 210mm;
            margin-top: 10px;
            position: relative;
            zoom: 0.83;
        }
        max-width: 420mm;
        padding: 0px;
        
    }
`;
