import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    margin-top: 20px;
`;


export const Form = styled.form`
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-top: 10px;
    margin-bottom: 20px;
    align-items: center;
    gap: 10px;
`;