import React, { useState } from 'react';
import api from '../api/api';
import DriverAddCompont from '../components/Drivers/DriverAdd';
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';

const DriverAdd = () => {
    let location = useLocation()
    const [loading, setLoading] = useState(false);
    const add_driver = async (data, clear) => {
        if (loading === false) {
            const response = await api.post("/driver/", data)
            setLoading(true)
            if (response.data.success === true) {
                Swal.fire({
                    title: 'Success!',
                    text: "Haydovchi qo'shildi!",
                    icon: 'success'
                });
                setLoading(false)
                clear();
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: "Haydovchi qo'shishda xatolik mavjud!",
                    icon: 'error'
                });
                setLoading(false)
            }
        }
    }

    return (
        <DriverAddCompont add_driver={add_driver} nav_page={location?.state?.page} />
    );
}

export default DriverAdd;