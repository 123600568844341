import React, { useEffect, useState } from 'react'

import api from "../api/api"
import { useParams } from 'react-router-dom';
import MessageDetailComponent from '../components/MessageDetail';

const get_message = async (id) => {
    const response = await api.get(`/messages/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    } else {
        console.log(response.data.error)
        return {}
    }
}

const MessageDetail = () => {
    const { ms_id } = useParams()
    const [message, setMessage] = useState({})
    useEffect(() => {
        async function get_message_fetch(ms_id) {
            const res = await get_message(ms_id)
            setMessage(res)
        }
        get_message_fetch(ms_id)
    }, [ms_id])
    return (
        <MessageDetailComponent message={message} />
    );
}

export default MessageDetail;