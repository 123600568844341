import React, { useState } from 'react'
import ChatComponent from '../components/Chat';
import api from "../api/api"

const get_driver_filter = async (params) => {
    const response = await api.get('/driver-sms/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    } else {
        console.log(response.data.error)
        return []
    }
}

const Chat = () => {
    return (
        <>
            <ChatComponent get_driver_filter={get_driver_filter} />
        </>
    );
}

export default Chat;