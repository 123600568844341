import styled from "styled-components";
import Select from 'react-select';

export const SearchSelect = styled(Select)`
    box-sizing: border-box;
    font-size: 16px;
    font-family: "Inter";
    height: 36px;
    outline: none;
    :focus{
        border: 1px solid #03cafc;
    }
    border-radius: 8px;
    
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
`;

export const Form = styled.form`
    display: grid;
    grid-template-columns: 33% 33% 32%;
    gap: 10px;
`;