import React, { useEffect, useState } from 'react'
import { Title } from '../../Dashboard/Cards/styles';
import { Container, ContainerFull, ItemDiv, MainContainer, SubTitle, TabBtn, TabContainer, TabMenuContainer, TitleItem } from './styles';
import { CarTab, DriverTab, PaymentTab, Documents, Contracts, DebitTab } from '../Tabs';
import { Input } from '../../Cars/CarAdd/styles';
import api from '../../../api/api';
import TexKorikTab from '../Tabs/TexKorikTab';
import { BackBtn } from '../DriverEdit/styles';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
const Details = ({ driver, car, nav_page }) => {
    let navigate = useNavigate();
    const [page, setPage] = useState("driver");
    const [davomat, setDavomat] = useState(false)

    useEffect(() => {
        async function get_davomat() {
            const response = await api.get(`/davomat/${driver.id}/`)
            if (response.data.success === true) {
                setDavomat(response.data.data)
            }
        }
        get_davomat()
    }, [driver])

    const set_davomat = async () => {
        const response = await api.post(`/davomat/${driver.id}/`)
        if (response.data.success === true) {
            setDavomat(response.data.data)
        }
    }
    const return_back = () => {
        navigate('/drivers', { state: { page: nav_page } })
    }
    return (
        <ContainerFull>
            <Title>Haydovchi</Title>
            <Container>
                <BackBtn onClick={return_back}><FaLongArrowAltLeft /></BackBtn>
                <MainContainer>
                    <ItemDiv>
                        <TitleItem color='#595959'>{driver.name}</TitleItem>
                        <SubTitle>{driver.phone}</SubTitle>
                    </ItemDiv>
                    <ItemDiv>
                        <TitleItem color='#069c3d'>Mashina</TitleItem>
                        <SubTitle>{driver.car}</SubTitle>
                    </ItemDiv>
                    <ItemDiv>
                        <TitleItem color='#f22e2e'>Qarzdorlik</TitleItem>
                        <SubTitle>{driver.credit} so'm</SubTitle>
                    </ItemDiv>
                    <ItemDiv>
                        <TitleItem color='#2e80f2'>Litsenziya</TitleItem>
                        <SubTitle>{driver.contract}</SubTitle>
                    </ItemDiv>
                    <ItemDiv>
                        <TitleItem color='#2e80f2' style={{ marginBottom: "0px", fontSize: "15px" }}>
                            Texnik ko'rik</TitleItem>
                        <Input type="checkbox" style={{ height: "42px" }} checked={davomat} onChange={set_davomat} />
                    </ItemDiv>
                </MainContainer>
                <TabMenuContainer>
                    <TabBtn
                        active={page === "driver" ? true : false}
                        onClick={() => setPage("driver")}
                    >Haydovchi</TabBtn>
                    <TabBtn
                        active={page === "tex-korik" ? true : false}
                        onClick={() => setPage("tex-korik")}
                    >Texnik ko'rik</TabBtn>
                    <TabBtn
                        active={page === "payment" ? true : false}
                        onClick={() => setPage("payment")}
                    >To'lovlar</TabBtn>
                    <TabBtn
                        active={page === "debit" ? true : false}
                        onClick={() => setPage("debit")}
                    >Qarzdorlik tarixi</TabBtn>
                    <TabBtn
                        active={page === "documents" ? true : false}
                        onClick={() => setPage("documents")}
                    >Yo'l varaqasi</TabBtn>
                    <TabBtn
                        active={page === "car" ? true : false}
                        onClick={() => setPage("car")}
                    >Mashina</TabBtn>
                    <TabBtn
                        active={page === "contract" ? true : false}
                        onClick={() => setPage("contract")}
                    >Shartnoma</TabBtn>
                </TabMenuContainer>
                <TabContainer>
                    {page === "driver" ? <DriverTab driver={driver} /> :
                        page === "car" ? <CarTab car={car} /> :
                            page === "tex-korik" ? <TexKorikTab car={car} driver={driver} /> :
                                page === "payment" ? <PaymentTab driver_id={driver.id} /> :
                                    page === "contract" ? <Contracts driver_id={driver.id} /> :
                                        page === "documents" ? <Documents driver={driver} car={car} /> : 
                                        page == "debit" ? <DebitTab driver_id={driver.id} car_id={car.id}/>:null}
                </TabContainer>
            </Container>
        </ContainerFull>

    );
}

export default Details;