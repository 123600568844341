import { Route, Routes } from "react-router-dom"
import {
  Dashboard, Drivers, Cars, Staffs,
  Balance, Settings, Notifications, DriverAdd, CarAdd, StaffAdd, DriverDetail, Vedmost, PriceList, CarDetail, Shartnoma, CarEdit, DriverEdit, TexView, StaffEdit, CarPayment, Chat, Messages, MessageDetail, Litsenziya
} from "./pages";
import * as ROUTES from "./Constants/routes"
import DriversDelete from "./pages/drivers_delete";
import CarsDelete from "./pages/carsDelete";

const Routers = () => {
  return (
    <Routes>
      <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
      <Route path={ROUTES.DRIVERS} element={<Drivers />} />
      <Route path={ROUTES.CARS} element={<Cars />} />
      <Route path={ROUTES.STAFFS} element={<Staffs />} />
      <Route path={ROUTES.BALANCE} element={<Balance />} />
      <Route path={ROUTES.SETTINGS} element={<Settings />} />
      <Route path={ROUTES.NOTIFICATION} element={<Notifications />} />
      <Route path={ROUTES.DRIVER_ADD} element={<DriverAdd />} />
      <Route path={ROUTES.CAR_ADD} element={<CarAdd />} />
      <Route path={ROUTES.STAFF_ADD} element={<StaffAdd />} />
      <Route path="/driver-detail/:driver_id" element={<DriverDetail />} />
      <Route path={ROUTES.VEDMOST} element={<Vedmost />} />
      <Route path={ROUTES.PRICE_LIST} element={<PriceList />} />
      <Route path="/car-detail/:car_id" element={<CarDetail />} />
      <Route path="/shartnoma/:driver_id" element={<Shartnoma />} />
      <Route path="/car-edit/:car_id" element={<CarEdit />} />
      <Route path="/driver-edit/:driver_id" element={<DriverEdit />} />
      <Route path="/tex-view" element={<TexView />} />
      <Route path="/staff-edit/:staff_id" element={<StaffEdit />} />
      <Route path="/car-payment/:car_id" element={<CarPayment />} />
      <Route path="/drivers-delete" element={<DriversDelete />} />
      <Route path="/cars-delete" element={<CarsDelete />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/messages/:ms_id" element={<MessageDetail />} />
      <Route path="/litsenziya" element={<Litsenziya />} />
    </Routes>
  )
}

export default Routers