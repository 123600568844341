import styled from "styled-components";


export const Form = styled.form`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: flex-start;
    width: 100%;
`;


export const CheckDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
`;

export const Title = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: #000;
    text-align: center;
    font-family: "Cambria";
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`;

export const CheckItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3px;
    grid-gap: 5px;
`;

export const Text = styled.span`
    font-size: 15px;
    color: #000;
    font-family: "Cambria";
`;

export const QRDiv = styled.div`
    display: grid;
    grid-template-columns: 65% 35%;
    margin-top: 10px;
`;

export const Input = styled.input`
    border: 1px solid #8E8EA1;
    box-sizing: border-box;
    font-size: 16px;
    padding-left: 5px;
    font-family: "Inter";
    height: 36px;
    outline: none;
    :focus{
        border: 1px solid #03cafc;
    }
    border-radius: 8px;
`;

export const Button = styled.button`
    background-color: #6160DC;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    height: 36px;
    width: 120px;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    :hover{
        opacity: 50%;
    }
    margin-top:10px;
    margin-bottom: 10px;
    
`;