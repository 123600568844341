import React from 'react'
import Pagination from 'react-js-pagination'
import "./styles.css";

const PaginationComponent = ({ activePage, loadData, items_count, range_display, items_per_page }) => {
    return <Pagination
        activePage={Number(activePage)}
        itemsCountPerPage={items_per_page}
        totalItemsCount={items_count}
        pageRangeDisplayed={range_display}
        onChange={(page) => loadData(page)}
        innerClass="pagination"
        activeLinkClass="pg_active"
        itemClass="item_class"
        linkClass="link_class"
        hideFirstLastPages={true}

    />
}

export default PaginationComponent;