import React, { useEffect, useRef, useState } from 'react'
import { Container, Search, Title, TitleContainer } from '../../Cars/CarList/styles';
import { DetailBtn } from '../../Drivers/DriverList/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../Table/styles';
import { DateInput, ExportBtn } from './styles';
import { FaEdit, FaTrash, FaFileExcel, FaPrint } from 'react-icons/fa';
import PaymentDeleteModal from '../DeleteModal';
import api from "../../../api/api";
import PaymentEditModal from '../EditModel';
import { get_permission } from '../../../Constants/get_permission';
import PaginationComponent from '../../Paginations';
import { useReactToPrint } from 'react-to-print';
import { CheckDiv, CheckItem, Text } from '../../Drivers/Tabs/PaymentTab/styles';
const get_payment_types = async () => {
    const response = await api.get('/payment-type/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const get_payment = async (id) => {
    const response = await api.get(`/payment/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {}
    }
}

const get_payment_otchot = async () => {
    const response = await api.get(`/payment-ochot/`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}

const Print = React.forwardRef((props, ref) => {
    console.log(props.printPayment);
    const date = new Date()
    return (
        <CheckDiv ref={ref}>
            <Title style={{ backgroundColor: "#999999", padding: "5px" }}>Web-taxi</Title>
            <Title>Z - ОТЧЕТ </Title>
            {props?.printPayment?.map((data, index) => (
                <div style={{ marginTop: "15px" }}>
                    <CheckItem>
                        <Text>To'lovchi:</Text>
                        <Text>{data.driver?.car_number}</Text>
                    </CheckItem>
                    <CheckItem>
                        <Text>Summa:</Text>
                        <Text>{data.summa}</Text>
                    </CheckItem>
                    <CheckItem>
                        <Text>Turi:</Text>
                        <Text>{data.type}</Text>
                    </CheckItem>
                    <CheckItem>
                        <Text>Sana:</Text>
                        <Text>{data.date}</Text>
                    </CheckItem>
                </div>
            ))}
            <div style={{ marginTop: "15px" }}>
                <CheckItem>
                    <Text style={{ fontWeight: '600' }}>Sana:</Text>
                    <Text style={{ fontWeight: '600' }}>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</Text>
                </CheckItem>
                <CheckItem>
                    <Text style={{ fontWeight: '600' }}>Jami Summa:</Text>
                    <Text style={{ fontWeight: '600' }}>{props.totalSumma}</Text>
                </CheckItem>
            </div>
        </CheckDiv>
    )
});

const Payments = ({ payments, filter_payments }) => {
    const [search, setSearch] = useState("");
    const [date_start, setDate_start] = useState(null);
    const [date_end, setDate_end] = useState(null);
    const [isopenD, setIsOpenD] = useState(false);
    const [isopenE, setIsOpenE] = useState(false);
    const [delte_id, setDelete_id] = useState(null);
    const [edit_id, setEdit_id] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState([])
    const [payment, setPayment] = useState({})
    const [permission, setPermission] = useState([])
    const [loading, setLoading] = useState(false);
    const [printPayment, setPrintPayment] = useState([]);
    const [totalSumma, setTotalSumma] = useState(0);
    const componentRef = useRef();
    useEffect(() => {
        async function get_payment_types_fetch() {
            let response = await get_payment_types()
            setPaymentTypes(response)
        }
        get_payment_types_fetch()
    }, [])

    useEffect(() => {
        async function get_payment_ochot_fetch() {
            let response = await get_payment_otchot()
            setPrintPayment(response)
            var summa = 0
            for (var res of response) {
                summa = summa + res.summa
            }
            setTotalSumma(summa)
        }
        get_payment_ochot_fetch()
    }, [])
    useEffect(() => {
        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])
    useEffect(() => {
        async function get_payment_fetch() {
            let response = await get_payment(edit_id)
            setPayment(response)
        }
        get_payment_fetch()
    }, [edit_id])
    const SearchFilter = (value) => {
        setSearch(value)
        if (date_start !== null && date_end !== null) {
            filter_payments({
                search: value,
                date_end: date_end,
                date_start: date_start
            })
        } else {
            filter_payments({
                search: value
            })
        }
    }
    const DateEndFilter = (value) => {
        setDate_end(value)
        if (date_start !== null) {
            filter_payments({
                search: search,
                date_end: value,
                date_start: date_start
            })
        }
    }
    const DateStartFilter = (value) => {
        setDate_start(value)
        if (date_end !== null) {
            filter_payments({
                search: search,
                date_end: date_end,
                date_start: value
            })
        }
    }
    const DeletePayment = async () => {
        if (loading === false) {
            const response = await api.delete(`/payment/${delte_id}/`)
            setLoading(true)
            if (response.data.success === true) {
                filter_payments({
                    search: search,
                    date_start: date_start,
                    date_end: date_end
                })
                setIsOpenD(false)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
    }

    const EditPayment = async (data) => {
        if (loading === false) {
            const response = await api.put(`/payment/${edit_id}/`, data)
            setLoading(true)
            if (response.data.success === true) {
                filter_payments({
                    search: search,
                    date_start: date_start,
                    date_end: date_end
                })
                setIsOpenD(false)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const loadData = page => {
        filter_payments({
            page: page,
            search: search,
            date_start: date_start,
            date_end: date_end
        })
    }
    const open_delete = (id) => {
        setDelete_id(id);
        setIsOpenD(true)
    }

    const open_edit = (id) => {
        setEdit_id(id);
        setIsOpenE(true)
    }
    const export_data = async () => {

        const response = await api.get('/export-payment/', { params: { date_start: date_start, date_end: date_end } })
        if (response.data.success === true) {
            window.open("https://backoffice.webtaxi.uz" + response.data.data)
        }
    }

    return (
        <Container>
            <TitleContainer>
                <Title>To'lovlar</Title>
                <Search placeholder='Qidirish ... ' onChange={(e) => SearchFilter(e.target.value)} />
                <DateInput type="date" onChange={(e) => DateStartFilter(e.target.value)} />
                <DateInput type="date" onChange={(e) => DateEndFilter(e.target.value)} />
                <ExportBtn onClick={() => export_data()}><FaFileExcel /></ExportBtn>
                <ExportBtn onClick={() => handlePrint()} style={{ backgroundColor: "blueviolet" }}><FaPrint /></ExportBtn>
            </TitleContainer>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TR>
                            <TH>№</TH>
                            <TH>Haydovchi</TH>
                            <TH>Telefon raqam</TH>
                            <TH>Summa</TH>
                            <TH>To'lov turi</TH>
                            <TH>Sana</TH>
                            {permission?.includes(9) ? <TH>O'chirish</TH> : null}
                            {permission?.includes(8) ? <TH>Tahrirlash</TH> : null}
                        </TR>
                    </TableHead>
                    <TableBody>
                        {payments?.result?.map((dat, index) => (
                            <TR>
                                <TD>{index + 1}</TD>
                                <TD>{dat.driver?.name}</TD>
                                <TD>{dat.driver?.phone}</TD>
                                <TD>{dat.summa}</TD>
                                <TD>{dat.is_debit == false ? dat.type: dat.status == 0? 'Azolik badali uchun qarz': `Daromad solig'i uchun qarz`}</TD>
                                <TD>{dat.date}</TD>
                                {permission?.includes(9) && dat.is_debit == false ? <TD><DetailBtn bg="#f5424b" to="#" onClick={() => open_delete(dat.id)}><FaTrash /></DetailBtn></TD> : null}
                                {permission?.includes(8)  && dat.is_debit == false ? <TD><DetailBtn bg="#f5c842" to="#" onClick={() => open_edit(dat.id)}><FaEdit /></DetailBtn></TD> : null}
                            </TR>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <PaymentDeleteModal isOpen={isopenD} setIsOpen={setIsOpenD} DeletePayment={DeletePayment} />
            <PaymentEditModal isOpen={isopenE} setIsOpen={setIsOpenE} paymentTypes={paymentTypes}
                payment={payment} EditPayment={EditPayment}
            />
            <PaginationComponent
                activePage={payments?.pagination?.current_page}
                items_count={payments?.pagination?.items_count}
                range_display={5}
                loadData={loadData}
                items_per_page={payments?.pagination?.number_of_object}
            />
            <div style={{ display: "none" }}>
                <Print ref={componentRef} printPayment={printPayment} totalSumma={totalSumma} />

            </div>
        </Container>
    );
}

export default Payments;