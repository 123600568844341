import axios from "axios";

// const BASE_URL = "http://127.0.0.1:8000/api"
const BASE_URL = "https://backoffice.webtaxi.uz/api"

const token = localStorage.getItem('token')

export default axios.create({
    baseURL: BASE_URL,
    headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json'
    }
});