import React, { useEffect, useState } from 'react';
import { Container, Title, TitleContainer } from '../../Cars/CarList/styles';
import { Button, Form, FormItem, FormItemCheckbox, Input, Label } from './styles';


const StaffEditComponent = ({ permissions, add_staff, stff }) => {
    const [name, setName] = useState("")
    const [username, setUsername] = useState("")
    const [phone, setPhone] = useState("")
    const [permission, setPermission] = useState([])
    const [is_active, setIsActive] = useState('')


    const set_data = () => {
        setName(stff.name)
        setUsername(stff.username)
        setPhone(stff.phone)
        setPermission(stff.permission)
        setIsActive(stff.is_active)
    }


    useEffect(() => {
        set_data();
    }, [stff]);


    const add = (e) => {
        const data = {
            name: name,
            username: username,
            phone: phone,
            permission: permission
        }
        add_staff(data, ClearInput)
        e.preventDefault();
    }
    const ClearInput = () => {
        setPermission([])
        setName("")
        setPhone("")
        setUsername("")
    }
    const change_permission = (pr) => {
        var new_array = true
        var arr = []
        for (var p of permission) {
            if (p === pr) {
                new_array = false
            } else {
                arr.push(p)
            }
        }
        if (new_array === true) {
            setPermission([pr, ...permission])

        } else {
            setPermission(arr)
        }
    }

    return (
        <Container>
            <TitleContainer>
                <Title>Xodim qo'shish</Title>
            </TitleContainer>
            <Form onSubmit={add}>
                <FormItem>
                    <Label>F.I.O </Label>
                    <Input type="text" placeholder='F.I.O ' onChange={(e) => setName(e.target.value)} required value={name} />
                </FormItem>
                <FormItem>
                    <Label>Telefon raqam</Label>
                    <Input type="text" placeholder='Telefon raqam' onChange={(e) => setPhone(e.target.value)} required value={phone} />
                </FormItem>
                <FormItem>
                    <Label>Username</Label>
                    <Input type="text" placeholder='Username' onChange={(e) => setUsername(e.target.value)} required value={username} />
                </FormItem>
                <FormItemCheckbox style={{ flexDirection: "row" }}>
                    <Label>Active</Label>
                    <Input type="checkbox" onClick={(e) => setIsActive(e.target.checked)} checked={is_active} />
                </FormItemCheckbox>
                {permissions?.map((pr, index) => (
                    <FormItemCheckbox style={{ flexDirection: "row" }} key={index}>
                        <Label>{pr.name}</Label>
                        <Input type="checkbox" onClick={() => change_permission(pr.code)} checked={permission?.includes(pr.code)} />
                    </FormItemCheckbox>
                ))}

                <FormItem>
                    <Label></Label>
                    <Button>Tahrirlash</Button>
                </FormItem>

            </Form>
        </Container>
    );
}

export default StaffEditComponent;