import React, { useEffect, useState } from 'react'
import { Container, Div, DivFirst, DivSecond, Logo, SubText, Text } from './styles';
import QRCode from 'react-qr-code'
import { Table, TableBody, TD, TR } from '../../../../Vedmost/styles';

const DateTable = (daysOfYear) => {
    var table_array = [];
    var i = 0;
    while (i < daysOfYear.length) {
        console.log(i);
        if (i === daysOfYear.length - 1 && daysOfYear.length % 2 === 1) {
            let dt = {
                date1: daysOfYear[i],
                date2: null
            }
            table_array.push(dt);
        } else {
            let dt = {
                date1: daysOfYear[i],
                date2: daysOfYear[i + 1]
            }
            table_array.push(dt);
        }
        i = i + 2;
    }
    return table_array

}
const PathMapComponent = React.forwardRef((props, ref) => {
    const [is_load, setIsload] = useState(false)
    let date = new Date()
    useEffect(() => {
        setIsload(true)
    }, [props.driver])
    let date_end = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    const weeks = ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba"]
    var daysOfYear = [];
    for (var d = date; d <= date_end; d.setDate(d.getDate() + 1)) {
        daysOfYear.push(new Date(d));
    }
    daysOfYear.push(date_end)
    const get_current_date = (date) => {
        if (date.getDate() < 10) {
            var day = "0" + String(date.getDate())
        } else {
            var day = String(date.getDate())
        }
        if (date.getMonth() < 9) {
            var month = "0" + String(date.getMonth() + 1)
        } else {
            var month = String(date.getMonth() + 1)
        }

        return day + "." + month + "." + String(date.getFullYear())
    }
    const now = new Date()
    const table_array = DateTable(daysOfYear);
    return (
        <Container ref={ref}>
            <DivFirst>
                <Logo>Web-<span style={{ color: "#fca103" }}>taxi</span></Logo>
                <SubText style={{ fontSize: "24px", textAlign: "center" }}>Yo'l varaqasi : R: 1307/<span style={{ paddingRight: "50px", borderBottom: "1px solid black" }}></span></SubText>
                <Text><span style={{ float: "left" }}>{get_current_date(now)} dan</span><span style={{ float: "right" }}>{get_current_date(date_end)} gacha</span></Text>
                <Text>Haydovchi : <SubText style={{ marginLeft: "10px" }}>{props.driver.name}</SubText></Text>
                <Text>Avto raqami : <SubText style={{ marginLeft: "10px" }}>{props.car.number}</SubText></Text>
                <Text>Avto rusumi : <SubText style={{ marginLeft: "10px" }}>{props.car.model}</SubText></Text>
                <Text>Ruhsat : <SubText style={{ marginLeft: "10px" }}>shaharda yo'lovchi tashish uchun.</SubText></Text>
                <Text>Ko'rikdan o'tish vaqti : <SubText style={{ marginLeft: "10px" }}>Har kuni 11 : 00 gacha</SubText></Text>
                <Text>Kelgusi davriy texnik ko'rik  : <SubText style={{ marginLeft: "10px", borderBottom: "1px solid #999", paddingLeft: "50px" }}>
                    <span>-</span><span style={{ paddingLeft: "50px" }}>-</span>20<span style={{ paddingLeft: "50px" }}>-yil</span></SubText></Text>
                <Text>Majburiy Sug'rta muddati  : <SubText style={{ marginLeft: "10px", borderBottom: "1px solid #999", paddingLeft: "50px" }}>
                    <span>-</span><span style={{ paddingLeft: "50px" }}>-</span>20<span style={{ paddingLeft: "50px" }}>-yil</span></SubText></Text>
                <Text>Avtomobil litsenziya muddati  : <SubText style={{ marginLeft: "10px", borderBottom: "1px solid #999" }}>{props.driver.contract} - yil</SubText></Text>
                <SubText style={{ fontSize: "24px", textAlign: "center" }}>Avtomobilning buyurtma bo’yicha boshqa yo’nalishga o’tkazish</SubText>
                <Text style={{ fontSize: "24px", textAlign: "center" }}>Ma’lumot</Text>
                <SubText style={{}}>
                    1-buyurtma : <span style={{ paddingLeft: "130px", borderBottom: "1px solid #999" }}></span>ga<span style={{ paddingLeft: "20px" }}></span>
                    <span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-<span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>dan
                    <span style={{ paddingLeft: "20px" }}></span>
                    <span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-<span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-202
                    <span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-yil
                </SubText>
                <SubText style={{}}>
                    2-buyurtma : <span style={{ paddingLeft: "130px", borderBottom: "1px solid #999" }}></span>ga<span style={{ paddingLeft: "20px" }}></span>
                    <span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-<span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>dan
                    <span style={{ paddingLeft: "20px" }}></span>
                    <span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-<span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-202
                    <span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-yil
                </SubText>
                <SubText style={{}}>
                    3-buyurtma : <span style={{ paddingLeft: "130px", borderBottom: "1px solid #999" }}></span>ga<span style={{ paddingLeft: "20px" }}></span>
                    <span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-<span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>dan
                    <span style={{ paddingLeft: "20px" }}></span>
                    <span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-<span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-202
                    <span style={{ paddingLeft: "30px", borderBottom: "1px solid #999" }}></span>-yil
                </SubText>
                <SubText style={{ textIndent: "40px" }}>
                    Xurmatli haydovchi! Faoliyatingiz davomida sizdan Respublikamiz Qonunlariga rioya etishingizni hamda o’z huquq va imtiyozlaringizni unutmagan holda faoliyat yuritishingizni suraymiz.
                </SubText>
                <SubText><span style={{ borderBottom: "1px solid #999", width: "200px", float: "right", marginTop: "10px" }}></span></SubText>
                <Text style={{ fontSize: "22px" }}>Haydovchi jamiyatda muhim shaxslardan biridir.!</Text>
                <Div>
                    <SubText>Ushbu yo’l varaqasi  Web-taxi mulkidir va haydovchi O’zbekiston Respulikasi Qonunlari va Kompaniya himoyasidadir </SubText>
                    {is_load ?
                        <QRCode value={String(props.driver.id)} size={110} /> : null}
                </Div>
                <br />
            </DivFirst>
            <DivSecond>
                <Table style={{ marginTop: "100px" }}>
                    {/* <TableHead>
                    <TR>
                        <TH></TH>
                        <TH></TH>
                        <TH></TH>
                        <TH></TH>
                        <TH></TH>
                        <TH></TH>
                        <TH></TH>
                        <TH></TH>
                    </TR>
                </TableHead> */}
                    <TableBody>
                        {table_array.map((dat, index) => (
                            <TR>
                                <TD style={{ width: "10%" }}>{dat.date1.getDate()}</TD>
                                <TD style={{ width: "15%" }}>{weeks[dat.date1.getDay()]}</TD>
                                <TD style={{ width: "10%" }}></TD>
                                <TD style={{ width: "10%" }}></TD>
                                <TD style={{ width: "10%" }}>{dat.date2?.getDate()}</TD>
                                <TD style={{ width: "15%" }}>{weeks[dat.date2?.getDay()]}</TD>
                                <TD style={{ width: "10%" }}></TD>
                                <TD style={{ width: "10%" }}></TD>
                            </TR>
                        ))}
                    </TableBody>

                </Table>
            </DivSecond>


        </Container >
    );
})

export default PathMapComponent;