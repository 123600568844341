import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom"
import styled from "styled-components";
import Sidebar from "./sidebar/Sidebar";
import TopBar from "./topbar/Topbar";
import Routers from "./router";
import SignIn from "./components/signin/Signin";
import { useIdleTimer } from "react-idle-timer";
function App() {
  const [token, setToken] = useState(null);
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const tokenstore = localStorage.getItem('token')
    setToken(tokenstore)
  }, [])

  const handleOnIdle = event => {
    LogOut();
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    console.log('user did something', event)
  }
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  const LogOut = () => {
    localStorage.clear()
    setToken(null)
  }
  if (!token) {
    return <SignIn setToken={setToken} />
  }

  return (
    <Router>
      <Container>
        <Sidebar />
        <SubContainer>
          <TopBar LogOut={LogOut} />
          <Routers />
        </SubContainer>
      </Container>
    </Router>

  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #E5E5E5;
  height: 100vh;
  top: 0px;
  bottom:0px;
  left: 0px;
  right: 0px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 30px;
  width: 100%;
  margin-left: 280px;
  top: 0px;
  bottom:0px;
  left: 0px;
  right: 0px;
  overflow-y: scroll;
    &::-webkit-scrollbar {
    display: none;
}
`;


export default App;
