import React, { useState } from 'react';
import { Title } from '../Dashboard/Cards/styles';
import { TabBtn, TabContainer, TabMenuContainer } from '../Drivers/Detail/styles';
import { Container } from './styles';
import CarModelPanel from './TabPanels/Car';
import PaymentTypePanel from './TabPanels/Payment';
import DoromadSoligiPanel from './TabPanels/Solig';

export default function SettingsComponent({ add_car_model, paymentTypes, add_payment_types, car_models, solig, add_solig }) {
    const [page, setPage] = useState("car_model");
    return (
        <Container>
            <Title style={{ fontSize: "20px" }}>Sozlamalar</Title>
            <TabMenuContainer>
                <TabBtn
                    active={page === "car_model" ? true : false}
                    onClick={() => setPage("car_model")}
                >Mashina rusumi</TabBtn>
                <TabBtn
                    active={page === "payment_type" ? true : false}
                    onClick={() => setPage("payment_type")}
                >To'lov turi</TabBtn>
                <TabBtn
                    active={page === "daromad_soligi" ? true : false}
                    onClick={() => setPage("daromad_soligi")}
                >Daromad solig'i</TabBtn>
            </TabMenuContainer>
            <TabContainer>
                {page === "car_model" ?
                    <CarModelPanel
                        car_models={car_models}
                        add_car_model={add_car_model} /> :
                    page === "payment_type" ? <PaymentTypePanel
                        paymentTypes={paymentTypes}
                        add_payment_types={add_payment_types}
                    /> : page === "daromad_soligi" ? <DoromadSoligiPanel
                        solig={solig}
                        add_solig={add_solig}
                    /> : null}
            </TabContainer>
        </Container>
    );
}