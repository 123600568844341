import styled from "styled-components";


export const ButtonContainer = styled.div`
    display: flex;
    gap: 15px;
    padding: 20px;
    width: 100%;
`;

export const PrintButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    background-color: ${props=>props.bg};
    padding: 10px;
    color: white;
    font-size: 16px;
    font-style: normal;
    border-radius: 10px;
    border: none;
`;

