import React from 'react'
import { FaCar, FaDollarSign, FaMoneyBillAlt, FaUsers } from 'react-icons/fa';
import { Card, CardBody, CardContainer, CardIcon, CardSumma, Container, Subtitle, Text, Title } from './styles'



const DashboardCard = ({ widget }) => {
    return (
        <Container>
            <Title>Dashboard</Title>
            <CardContainer>
                <Card>
                    <CardBody>
                        <CardSumma>
                            <Subtitle>Kunlik tushum</Subtitle>
                            <Text>{widget.day_summa} so'm</Text>
                        </CardSumma>
                        <CardIcon bg="#387dfc">
                            <FaMoneyBillAlt size={24} color="white" />
                        </CardIcon>

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardSumma>
                            <Subtitle>Oylik tushum</Subtitle>
                            <Text>{widget.month_summa} so'm</Text>
                        </CardSumma>
                        <CardIcon bg="#38cffc">
                            <FaDollarSign size={24} color="white" />
                        </CardIcon>

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardSumma>
                            <Subtitle>Haydovchilar</Subtitle>
                            <Text>{widget.driver} ta</Text>
                        </CardSumma>
                        <CardIcon bg="#b138fc">
                            <FaUsers size={24} color="white" />
                        </CardIcon>

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardSumma>
                            <Subtitle>Mashinalar</Subtitle>
                            <Text>{widget.car} ta</Text>
                        </CardSumma>
                        <CardIcon bg="#fcbe38">
                            <FaCar size={24} color="white" />
                        </CardIcon>

                    </CardBody>
                </Card>
            </CardContainer>
            <CardContainer style={{ marginTop: "25px" }}>
                <Card>
                    <CardBody>
                        <CardSumma>
                            <Subtitle>Kunlik to'lov jami qarzi</Subtitle>
                            <Text>{widget.kun_credit} so'm</Text>
                        </CardSumma>
                        <CardIcon bg="#387dfc">
                            <FaMoneyBillAlt size={24} color="white" />
                        </CardIcon>

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardSumma>
                            <Subtitle>Daromad solig'i jami qarzi</Subtitle>
                            <Text>{widget.car_credit} so'm</Text>
                        </CardSumma>
                        <CardIcon bg="#38cffc">
                            <FaDollarSign size={24} color="white" />
                        </CardIcon>

                    </CardBody>
                </Card>
            </CardContainer>
        </Container>
    )
}

export default DashboardCard;