import React from 'react'
import { Table, TableBody, TableHead, TD, TH, TR } from '../../../Vedmost/styles';
import { Container, DetailContainer, HeaderContainer, IjaraContainer, ItemContainer, SubText, Title, TitleContainer } from './styles';


const CarTab = React.forwardRef((props, ref) => {
    const car = props.car;
    return (
        <Container ref={ref}>
            <HeaderContainer>
                <TitleContainer>
                    <Title size="24px">WEB TAXI</Title>
                    <SubText size="13px">Dream Gold Andijon MCHJ</SubText>
                </TitleContainer>
                <TitleContainer style={{ marginLeft: "auto" }}>
                    <Title>ID</Title>
                    <SubText size="15px">{car.id}</SubText>
                </TitleContainer>
                <TitleContainer>
                    <Title>Yaratilgan sanasi</Title>
                    <SubText size="15px">{car.created}</SubText>
                </TitleContainer>
            </HeaderContainer>
            <DetailContainer>
                <Title size="18px" style={{ textAlign: "center" }}>Автомобил маълумотлари варакаси № {car.id}</Title>
                <ItemContainer>
                    <Title size="16px">Авто эгасининг Ф.И.Ш:</Title>
                    <SubText size="16px">{car.owner_name}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">Яшаш манзили :</Title>
                    <SubText size="16px">{car.address}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">Тугулган йили :</Title>
                    <SubText size="16px">{car.birthdate}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">СТИР :</Title>
                    <SubText size="16px">{car.stir}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">ЖЩШИР :</Title>
                    <SubText size="16px">{car.jshshir}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">Паспорт раками :</Title>
                    <SubText size="16px">{car.passport}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">Телефон раками :</Title>
                    <SubText size="16px">{car.phone}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">Автомобил русуми :</Title>
                    <SubText size="16px">{car.model}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">Давлат белгиси :</Title>
                    <SubText size="16px">{car.number}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">Ишлаб чикарилган йили :</Title>
                    <SubText size="16px">{car.year}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">Техник паспорт раками :</Title>
                    <SubText size="16px">{car.tex_passport}</SubText>
                </ItemContainer>
                <ItemContainer>
                    <Title size="16px">Берилган вакти :</Title>
                    <SubText size="16px">{car.tex_date}</SubText>
                </ItemContainer>
            </DetailContainer>
            <IjaraContainer>
                <Table style={{ width: "100%" }}>
                    <TableHead>
                        <TR>
                            <TH>№</TH>
                            <TH>Ракам</TH>
                            <TH>Дан</TH>
                            <TH>Гача</TH>
                            <TH>Ижара хаки</TH>
                            <TH>Status</TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {car.ijara?.map((dt, index) => (
                            <TR>
                                <TD>{index + 1}</TD>
                                <TD>{dt.raqam}</TD>
                                <TD>{dt.date_start}</TD>
                                <TD>{dt.date_end}</TD>
                                <TD>{dt.price}</TD>
                                <TD>{dt.status}</TD>
                            </TR>
                        ))}
                    </TableBody>
                </Table>
            </IjaraContainer>
        </Container>
    );
})

export default CarTab;