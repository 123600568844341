import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import api from '../api/api';
import CarsDeleteComponent from '../components/Cars/CarListDelete/Index';

const get_cars = async (params = {}) => {
    const response = await api.get('/car/', { params: params })
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {
            pagination: {},
            result: []
        }
    }
}

const CarsDelete = () => {
    let location = useLocation()
    const [pagination, setPagination] = useState({});
    const [cars, setCars] = useState([]);

    useEffect(() => {
        async function get_cars_fetch() {
            let response = await get_cars({ is_active: false })
            setPagination(response.pagination)
            setCars(response.result)
        }
        get_cars_fetch();
    }, []);

    const search_car = async (params) => {
        let response = await get_cars(params);
        setPagination(response.pagination)
        setCars(response.result)
    }



    return (
        <CarsDeleteComponent
            pagination={pagination}
            setPagination={setPagination}
            cars={cars}
            setCars={setCars}
            search_car={search_car}
            nav_page={location?.state?.page}
        />
    )
}


export default CarsDelete;