import React, { useEffect, useState } from 'react';
import { get_permission } from '../../../Constants/get_permission';
import { Button, FormItem, Input, Label, Select } from '../../Cars/CarAdd/styles';
import { Title } from '../../Cars/CarList/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../Table/styles';
import { StatusSpan } from '../DriverList/styles';
import { Container, Form, SearchSelect } from './styles';

const ShartnomaComponent = ({ add_contract, contracts, price_lists, cars }) => {
    const [permission, setPermission] = useState([])
    const [car, setCar] = useState(0);
    const [date_start, setDate_start] = useState("");
    const [date_end, setDate_end] = useState("");
    const [price_list, setPrice_list] = useState(0);
    const [day_credit, setDay_credit] = useState(0);
    const [is_credit, setIs_credit] = useState(false);
    const [day, setDay] = useState(1);
    useEffect(() => {
        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])
    const Add = (e) => {
        add_contract(
            {
                car: car.value,
                date_start: date_start,
                date_end: date_end,
                price_list: price_list,
                day: day,
                day_credit: day_credit
            }
        );
        e.preventDefault();
        setCar(0);
        setDate_start("")
        setDate_end("")
        setPrice_list(0)
        setDay_credit(0)
        setIs_credit(false)
        setDay(1)
    }

    const change_day = (value) => {
        if (value > 31) {
            setDay(31)
        } else {
            setDay(value)
        }
    }

    const change_credit_day = (value) => {
        if (value > 30) {
            setDay_credit(30)
        } else {
            setDay_credit(value)
        }
    }

    return (
        <Container>
            <Title>Shartnoma</Title>
            {permission?.includes(5) ?
                <Form onSubmit={Add}>
                    <FormItem>
                        <Label>Mashina</Label>
                        <SearchSelect
                            value={car}
                            options={cars}
                            onChange={setCar}
                        />
                    </FormItem>
                    <FormItem>
                        <Label>Tarif</Label>
                        <Select onChange={(e) => setPrice_list(e.target.value)} value={price_list} required>
                            {price_list === 0 ?
                                <option selected disabled value={0}>Tarifni tanlang</option> :
                                <option disabled>Tarifni tanlang</option>}
                            {price_lists?.map((dt, index) => (
                                <option value={dt.id}>{dt.name}</option>
                            ))}

                        </Select>
                    </FormItem>
                    <FormItem>
                        <Label>Boshlanish sana</Label>
                        <Input type="date" onChange={(e) => setDate_start(e.target.value)} value={date_start} required />
                    </FormItem>
                    <FormItem>
                        <Label>Tugash sanasi</Label>
                        <Input type="date" onChange={(e) => setDate_end(e.target.value)} value={date_end} required />
                    </FormItem>
                    <FormItem>
                        <Label>To'lov kuni</Label>
                        <Input type="number" onChange={(e) => change_day(e.target.value)} value={day} required />
                    </FormItem>
                    <FormItem>
                        <Label>Qarz hisoblansinmi</Label>
                        <Input type="checkbox" onChange={(e) => setIs_credit(e.target.checked)} value={is_credit} required />
                    </FormItem>
                    {is_credit ?
                        <FormItem>
                            <Label>Necha kunlik qarz</Label>
                            <Input type="number" onChange={(e) => change_credit_day(e.target.value)} value={day_credit} required />
                        </FormItem>
                        : null}
                    <FormItem>
                        <Label></Label>
                        <Button>Qo'shish</Button>
                    </FormItem>
                </Form> : null}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TR>
                            <TH>#</TH>
                            <TH>Mashina</TH>
                            <TH>Tarif</TH>
                            <TH>Boshlanish sana</TH>
                            <TH>Tugash sana</TH>
                            <TH>To'lov kuni</TH>
                            <TH>Status</TH>
                            <TH></TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {contracts?.map((dat, index) => (
                            <TR>
                                <TD>{index + 1}</TD>
                                <TD>{dat.car}</TD>
                                <TD>{dat.price_list}</TD>
                                <TD>{dat.date_start}</TD>
                                <TD>{dat.date_end}</TD>
                                <TD>{dat.day}</TD>
                                <TD>{dat.is_active ? <StatusSpan bg="green">Active</StatusSpan> : <StatusSpan bg="red">O'chirilgan</StatusSpan>}</TD>
                                <TD></TD>
                            </TR>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default ShartnomaComponent;