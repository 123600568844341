import React, { useEffect, useState } from 'react'
import api from '../api/api';
import { Chart, DashboardCard } from '../components/Dashboard';
import { get_permission } from '../Constants/get_permission';
const get_widget = async () => {
    const response = await api.get('/kassa-widget/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {}
    }
}


const Dashboard = () => {
    const [widget, setWidget] = useState({})
    const [permission, setPermission] = useState([])
    useEffect(() => {
        async function get_widget_fetch() {
            let response = await get_widget()
            setWidget(response)
            console.log(response);
        }
        get_widget_fetch()
    }, []);
    useEffect(() => {

        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])
    return (
        <>        
        {permission.includes(24)?
        <>
            <DashboardCard widget={widget} />
            <Chart chart_data={widget.chart_data} />
        </>
        :<h1>Dashboard</h1>}
        </>

    )
}


export default Dashboard;