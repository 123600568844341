import React, { useEffect, useState } from 'react'
import PriceListComponent from '../components/PriceList';
import api from '../api/api';

const get_price_list = async () => {
    const response = await api.get('/price-list/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}


const PriceList = () => {
    const [priceLists, setPriceLists] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function get_price_list_fetch() {
            let response = await get_price_list()
            setPriceLists(response)
        }
        get_price_list_fetch();
    }, []);
    const add_price_list = async (data) => {
        if (loading === false) {
            const response = await api.post('/price-list/', data)
            setLoading(true)
            if (response.data.success === true) {
                setPriceLists([response.data.data, ...priceLists])
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

    }
    return (
        <PriceListComponent priceLists={priceLists} add_price_list={add_price_list} />
    );
}

export default PriceList;