import React, { useState } from 'react'
import { Card, CardContent, Container, DeleteBtn, ExampleContainer, FilterBtn, LeftBottom, LeftMidle, LeftSection, LeftTop, Main, MessageContainer, MessageInput, Name, RightSection, SearchInput, SendBtn, Text, Title, Top, TopContent, TopFilter } from './styles';
import { FaTrash, FaPlus } from 'react-icons/fa'
import Swal from 'sweetalert2';
import api from '../../api/api';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';


const ChatComponent = ({ get_driver_filter }) => {
    const [midleHeight, setMidleHeight] = useState(true)
    const [bottomHeight, setBottomHeight] = useState(false)
    const [drivers, setDrivers] = useState([])
    const [searchDrovers, setSearchDrivers] = useState([])
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const get_all_drivers = async () => {
        const res = await get_driver_filter({ method: "all" })
        setDrivers(res)
    }

    const get_credit_drivers = async () => {
        const res = await get_driver_filter({ method: "credit" })
        setDrivers(res)
    }
    const get_birthday_drivers = async () => {
        const res = await get_driver_filter({ method: "birthday" })
        setDrivers(res)
    }
    const get_litsenziya_drivers = async () => {
        const res = await get_driver_filter({ method: "litsenziya" })
        setDrivers(res)
    }

    const get_litsenziya_drivers_3 = async () => {
        const res = await get_driver_filter({ method: "litsenziya_3" })
        setDrivers(res)
    }

    const get_drivers_search = async (value) => {
        const res = await get_driver_filter({ method: "search", search: value })
        setSearchDrivers(res)
    }

    const add_driver = (driver) => {
        setDrivers([driver, ...drivers])
        for (var i = 0; i < searchDrovers.length; i++) {

            if (searchDrovers[i].id === driver.id) {
                searchDrovers.splice(i, 1);
                setSearchDrivers([...searchDrovers])
                break

            }
        }
    }

    const remove_driver = (driver) => {
        for (var i = 0; i < drivers.length; i++) {

            if (drivers[i].id === driver.id) {
                drivers.splice(i, 1);
                setDrivers([...drivers])
                break

            }
        }
    }

    const SendMessage = async () => {
        setLoading(true)
        if (message === "") {
            setLoading(false)
            Swal.fire({
                title: 'Error!',
                text: "Xabar kiritilmadi!",
                icon: 'error'
            });
        } else {
            let dr = []
            for (let d of drivers) {
                dr.push(d.id)
            }
            if (dr.length === 0) {
                setLoading(false)
                Swal.fire({
                    title: 'Error!',
                    text: "Haydovchi tanlanmadi!",
                    icon: 'error'
                });
            } else {
                const res = await api.post('/messages/', {
                    drivers: dr,
                    text: message
                })
                console.log(res);
                if (res.data.success === true) {
                    Swal.fire({
                        title: 'Success!',
                        text: "SMS yuborildi!",
                        icon: 'success'
                    });
                    setMessage("")
                    setDrivers([])
                    setLoading(false)
                    navigate('/messages')
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: "SMS yuborishda xatolik yuz berdi!",
                        icon: 'error'
                    });
                    setLoading(false)
                }
            }
        }

    }


    const OnFocusSearch = (value) => {
        if (value !== "") {
            if (midleHeight !== false && bottomHeight !== true) {
                setMidleHeight(false)
                setBottomHeight(true)
            }
            get_drivers_search(value)
        } else {
            setMidleHeight(true)
            setBottomHeight(false)
            setSearchDrivers([])
        }


    }
    const close_bottom_left = () => {
        setMidleHeight(true)
        setBottomHeight(false)
    }
    return (
        <Container>
            <Top>
                <Title>SMS</Title>
            </Top>
            <Main>
                <LeftSection>
                    <TopFilter>
                        <FilterBtn onClick={get_credit_drivers}>Qarzdorlar</FilterBtn>
                        <FilterBtn onClick={get_birthday_drivers}>Tug'ilgan kun</FilterBtn>
                        <FilterBtn onClick={get_litsenziya_drivers}>Litsenziya</FilterBtn>
                        <FilterBtn onClick={get_litsenziya_drivers_3}>Lit / 3</FilterBtn>
                        <FilterBtn onClick={get_all_drivers}>Barchasi</FilterBtn>
                    </TopFilter>
                    <LeftTop>
                        <SearchInput onChange={(e) => OnFocusSearch(e.target.value)} placeholder="Qidirish ..." />
                    </LeftTop>
                    <LeftMidle className={midleHeight === true ? "max" : "min"}>
                        {drivers?.map((driver, index) => (
                            <Card key={index}>
                                <TopContent>
                                    <Name>{driver.name}</Name>
                                    <DeleteBtn onClick={() => remove_driver(driver)}><FaTrash /></DeleteBtn>
                                </TopContent>
                                <CardContent>
                                    <Text>Mashina : {driver.car}</Text>
                                    <Text>Qarzdorlik : {driver.credit}</Text>
                                    <Text>Tug'ligan sana : {driver.birthdate}</Text>
                                    <Text>Litsenziya : {driver.let}</Text>
                                </CardContent>
                            </Card>

                        ))}

                    </LeftMidle>
                    <LeftBottom className={bottomHeight === true ? "max" : "min"}>
                        {searchDrovers?.map((driver, index) => (
                            drivers?.find(dr => dr.id === driver.id) ? null : (
                                <Card key={index}>
                                    <TopContent>
                                        <Name>{driver.name}</Name>
                                        <DeleteBtn style={{ backgroundColor: "#009149" }} onClick={() => add_driver(driver)}><FaPlus /></DeleteBtn>
                                    </TopContent>
                                    <CardContent>
                                        <Text>Mashina : {driver.car}</Text>
                                        <Text>Qarzdorlik : {driver.credit}</Text>
                                        <Text>Tug'ligan sana : {driver.birthdate}</Text>
                                        <Text>Litsenziya : {driver.let}</Text>
                                    </CardContent>
                                </Card>
                            )


                        ))}
                    </LeftBottom>

                </LeftSection>
                <RightSection>
                    <Name style={{ width: "100%", margin: "20px", marginTop: "35px", color: "#fc8803" }}>Haydovchilarga tegishli ma'lumotlarni xabar ichiga joylash uchun yoriqnoma.</Name>

                    <ExampleContainer>
                        <Text>[haydovchi] = Haydovchining ismi familyasi</Text>
                        <Text>[qarz] = Haydovchining qarzi</Text>
                        <Text>[Litsenziya_muddati] = Litsenziya muddati</Text>
                        <Text>[mashina_qarz] = Daromad solig'idan qarzi</Text>
                    </ExampleContainer>
                    <MessageContainer>
                        <MessageInput onChange={(e) => setMessage(e.target.value)} value={message}>{message}</MessageInput>
                        <SendBtn onClick={() => SendMessage()}>Yuborish</SendBtn>
                    </MessageContainer>
                </RightSection>
            </Main>
            <Loader isOpen={loading} setIsOpen={setLoading} />
        </Container>
    )
}


export default ChatComponent;