import React from 'react'
import { Button, Card, Container, DateText, Main, Text, Title, Top } from './styles';
import { FaPaperPlane } from 'react-icons/fa'
import PaginationComponent from '../Paginations';
import { useNavigate } from 'react-router-dom';

const MessagesComponent = ({ messages, loadData }) => {
    const navigate = useNavigate()
    return (
        <Container>
            <Top>
                <Title>SMS Xabarlar</Title>
                <Button to="/chat"><FaPaperPlane /></Button>
            </Top>
            <Main>
                {messages?.result?.map((data, index) => (
                    <Card key={index} onClick={() => navigate(`/messages/${data.id}`)}>
                        <DateText>{data.date}</DateText>
                        <Text>{data.text}</Text>
                    </Card>
                ))}
            </Main>
            {messages.pagination?.pages_count > 1 ?
                <PaginationComponent
                    activePage={messages.pagination.current_page}
                    items_count={messages.pagination.items_count}
                    range_display={5}
                    loadData={loadData}
                    items_per_page={messages.pagination.number_of_object}
                /> : null}
        </Container>
    )
}

export default MessagesComponent;