import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import api from '../api/api'
import CarPaymentComponent from '../components/Cars/Payment'

const get_car_payments = async (id, params = {}) => {
    const response = await api.get(`/solig/${id}/`, { params: params })
    console.log(response);
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return {
            pagination: {},
            result: []
        }
    }
}
const get_car = async (id) => {
    const response = await api.get(`/car/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return null
    }
}

const CarPayment = () => {
    let location = useLocation()
    const { car_id } = useParams()
    const [car_payments, setCarPayments] = useState(null);
    const [car, setCar] = useState(null);

    useEffect(() => {
        async function get_car_fetch() {
            let response = await get_car(car_id)
            setCar(response)
        }
        get_car_fetch();
    }, [car_id]);
    useEffect(() => {
        async function get_car_payment_fetch() {
            let response = await get_car_payments(car_id)
            console.log(response);
            setCarPayments(response)
        }
        get_car_payment_fetch();
    }, [car_id]);

    const loadData = async (page) => {
        let response = await get_car_payments(car_id, {
            page: page
        })
        setCarPayments(response)
    }

    const add_car_ijara = async (data, id) => {
        let response = await api.post(`/solig/${id}/`, data)
        if (response.data.success === true) {
            setCarPayments(response.data.data)
        }
    }

    return (

        <CarPaymentComponent
            car_payments={car_payments}
            add_car_ijara={add_car_ijara}
            nav_page={location?.state?.page}
            car={car}
            loadData={loadData}
        />
    )
}

export default CarPayment;