import styled from "styled-components";
import { Link } from "react-router-dom";
export const Container = styled.div`
    display: flex;
    height: 50px;
    width: 100%;
`;

export const  NatDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 50px;
    grid-gap: 15px;
`;

export const Point = styled.span`
    position: absolute;
    height: 10px;
    width: 10px;
    border: none;
    border-radius: 5px;
    background-color: #0345fc;
    margin-left: 30px;
    margin-bottom: 25px;
`;

export const NatBtn = styled(Link)`
    background-color: white;
    text-decoration: none;
    height: 40px;
    width: 40px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #8E8EA1;
    border: 1px solid #8E8EA1;
    :hover{
        background-color: #7303fc;
        color: white;
        border: none;
    }
`; 

export const Search = styled.input`
    background-color: white;
    font-size: 15px;
    height: 38px;
    width: 300px;
    border: none;
    padding-left: 40px;
    box-sizing: border-box;
    font-family: 'Inter';
    border-radius: 10px;
    outline: none;
    :focus{
        border: 1px solid #03cafc;
    }   
`;