import React, { useState } from "react";
import { FaSearch, FaRegBell } from "react-icons/fa";
import { MdSettings,MdLockOutline } from "react-icons/md";
import { Container, Search, NatDiv, NatBtn } from "./styles";
import * as ROUTES from '../Constants/routes';
const TopBar = ({LogOut}) => {
    const [search, setSearch] = useState("")
    return (
        <Container>
            <form action={`/driver-detail/${search}`}>
            <Search placeholder="Search ..." type="number" onChange={(e)=>setSearch(e.target.value)}/>
            </form>
            <FaSearch size={20} color="#999"
                style={{
                position: "relative",
                marginTop:"10px",
                left: -290,
            }}/>
            <NatDiv>
                <NatBtn to={ROUTES.NOTIFICATION}>
                    <FaRegBell  size={20}/>
                </NatBtn>
                <NatBtn to={ROUTES.SETTINGS}>
                    <MdSettings  size={20}/>
                </NatBtn>
                <NatBtn to="#" onClick={LogOut}>
                    <MdLockOutline  size={20}/>
                </NatBtn>
            </NatDiv>
        </Container>
    )
};


export default TopBar;