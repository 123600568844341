import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import api from '../api/api'
import CarDetailComponent from '../components/Cars/Detail'

const get_car = async (id) => {
    const response = await api.get(`/car/${id}/`)
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return null
    }
}

const CarDetail = () => {
    let location = useLocation()
    const { car_id } = useParams()
    const [car, setCar] = useState(null);

    useEffect(() => {
        async function get_car_fetch() {
            let response = await get_car(car_id)
            setCar(response)
        }
        get_car_fetch();
    }, []);

    const add_car_ijara = async (data) => {
        let response = await api.post("/car-ijara-add/", data)
        if (response.data.success === true) {
            setCar(response.data.data)
        }
    }

    return (

        <CarDetailComponent
            car={car}
            add_car_ijara={add_car_ijara}
            nav_page={location?.state?.page}
        />
    )
}

export default CarDetail;