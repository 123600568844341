
import React, { useEffect, useRef, useState } from 'react'
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import api from '../api/api';
import VedmostComponent from '../components/Vedmost';


const get_vedmost= async () =>{
    const response = await api.get('/vedmost/')
    console.log(response);
    if (response.data.success === true){
        return response.data.data
    }
    else{
        return []
    }
}


const Vedmost = () => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [drivers, setDrivers] = useState([]);

    useEffect(()=>{
        async function get_drivers_fetch(){
            let response = await get_vedmost()
            
            setDrivers(response)
        }
        get_drivers_fetch();
    },[]);
    return ( 
        <Container>
            <TitleContainer>
                <Title>Kunlik vedmost</Title>
                <PrintButton bg="#4287f5" onClick={handlePrint}>
                    <FaPrint size={20} />
                </PrintButton>
            </TitleContainer>
            <DriverContainer>
                <VedmostComponent ref={componentRef} drivers={drivers}/>
            </DriverContainer>
        </Container>
     );
}
 
export default Vedmost;


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 15px;
`;

export const Title = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #22242C;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const PrintButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    background-color: ${props=>props.bg};
    padding: 10px;
    color: white;
    font-size: 16px;
    font-style: normal;
    border-radius: 10px;
    border: none;
    margin-left: auto;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const DriverContainer = styled.div`
    display: flex;
    overflow-x:auto;
`;