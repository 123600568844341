import React, { useEffect, useRef, useState } from 'react'
import { Container } from '../CarTab/styles';
import { Button, CheckDiv, CheckItem, Form, Input, QRDiv, Text, Title } from './styles';
import api from '../../../../api/api';
import { Select } from '../../../Cars/CarAdd/styles';
import { Table, TableBody, TableContainer, TableHead, TD, TH, TR } from '../../../Table/styles';
import Swal from 'sweetalert2';
import { useReactToPrint } from 'react-to-print';
import { PrintButton } from '../../../../pages/vedmost';
import { FaPrint, FaTrash } from 'react-icons/fa';
import { get_permission } from '../../../../Constants/get_permission';
import PaginationComponent from '../../../Paginations';
import QRCode from 'react-qr-code';
import { DateInput } from '../../../Balance/Payments/styles';
import { TitleContainer } from '../../DriverList/styles';
const get_payment_types = async () => {
    const response = await api.get('/payment-type/')
    if (response.data.success === true) {
        return response.data.data
    }
    else {
        return []
    }
}
const get_payments = async (params) => {
    const response = await api.get('/debit-history/', { params: params })
    if (response.data.success === true) {
        console.log(response.data.data);
        return response.data.data
    }
    else {
        return {
            pagination: {},
            result: []
        }
    }
}

const Print = React.forwardRef((props, ref) => {
    const date = new Date()
    return (
        <CheckDiv ref={ref}>
            <Title style={{ backgroundColor: "#999999", padding: "5px" }}>Web-taxi</Title>
            <Title>Квитанция: {props?.printPayment?.id}</Title>
            <CheckItem>
                <Text>Чоп этилган санаси:</Text>
                <Text>{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Авто Русуми:</Text>
                <Text>{props.printPayment?.driver?.car_model}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Авто Раками:</Text>
                <Text>{props.printPayment?.driver?.car_number}</Text>
            </CheckItem>
            <CheckItem>
                <Text>СТИР:</Text>
                <Text>{props.printPayment?.driver?.stir}</Text>
            </CheckItem>
            <CheckItem>
                <Text>ЖЩШИР:</Text>
                <Text>{props.printPayment?.driver?.jshshir}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Тўлов суммаси:</Text>
                <Text>{props.printPayment.summa}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Тулов Максади: </Text>
                <Text>{props.printPayment.type}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Тўлов санаси:</Text>
                <Text>{props.printPayment.date}</Text>
            </CheckItem>
            <CheckItem>
                <Text>Туловчи:</Text>
                <Text>{props.printPayment?.driver?.name}</Text>
            </CheckItem>
            <QRDiv>
                <Title style={{ fontSize: "15px", textAlign: "left", margin: "10px 0px", fontStyle: "italic" }}>Ёрдамингиз билан ривожланмоқдамиз!</Title>
                <QRCode value={String(props.printPayment.id)} size={64} style={{ marginLeft: "10px" }} />
            </QRDiv>

        </CheckDiv>
    )
});


const DebitTab = ({ driver_id, car_id }) => {
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [payments, setPayments] = useState({})
    const [summa, setSumma] = useState("");
    const [type, setType] = useState(0);
    const [printPayment, setPrintPayment] = useState({});
    const componentRef = useRef();
    const [printOpen, setPrintOpen] = useState(false)
    const [permission, setPermission] = useState([])
    const [loading, setLoading] = useState(false);
    const [date_start, setDate_start] = useState("");
    const [date_end, setDate_end] = useState("");
    const [curPage, setCurPage] = useState(1)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const PrintPaymentHandle = (data) => {
        setPrintPayment(data)
        setPrintOpen(true)
    }

    const loadData = async (page) => {
        let response = await get_payments({
            driver_id: driver_id,
            page: page,
            date_start:date_start,
            date_end:date_end
        })
        setPayments(response)
    }

    useEffect(() => {
        async function get_permission_fetch() {
            const res = await get_permission()
            setPermission(res)
        }
        get_permission_fetch()
    }, [])

    useEffect(() => {
        if (printOpen === true) {
            handlePrint()
            setPrintOpen(false)
        }

    }, [printPayment])
    useEffect(() => {
        async function get_payment_types_fetch() {
            let response = await get_payment_types()
            setPaymentTypes(response)
        }
        get_payment_types_fetch()
    }, []);
    useEffect(() => {
        async function get_payments_fetch() {
            let response = await get_payments({
                driver_id: driver_id,
                page: curPage,
                date_start:date_start,
                date_end:date_end
            })
            setPayments(response)
        }
        get_payments_fetch()
    }, [driver_id]);

    const add = async (e) => {
        e.preventDefault();
        if (loading === false) {
                let response = await api.post('/debit-history/', {
                    status: type,
                    driver_id: driver_id,
                    summa: summa,
                    car:car_id
                })
                console.log(response.data);
                setLoading(true)
                if (response.data.success === true) {
                    loadData(1)
                    Swal.fire({
                        title: 'Success!',
                        text: "Qarzdorlik qo'shildi!",
                        icon: 'success'
                    });
                    setType(0);
                    setSumma("");
                    setLoading(false)
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: "Qarz yozishda xatolik!",
                        icon: 'error'
                    });
                    setLoading(false)
                }
        }


    }
    const DateEndFilter = async (value) => {
        setDate_end(value)
        console.log(value);
        if (date_start !== null) {
            let res = await get_payments({
                driver_id: driver_id,
                date_end: value,
                date_start: date_start,
                page:1
            })
            setPayments(res)
        }
    }
    const DateStartFilter = async (value) => {
        setDate_start(value)
        if (date_end !== null) {
            let res = await get_payments({
                driver_id: driver_id,
                date_end: date_end,
                date_start: value,
                page:1
            })
            setPayments(res)
        }
    }

    const DeleteQarzdorlik = async(id) => {
        const response = await api.delete(`/delete-debit-history/${id}`)
        if (response.data.success === true) {
            loadData(curPage)
        }
    } 

    return (
        <Container>
            {permission?.includes(27) ?
                <Form onSubmit={add}>
                    <Input type="number" placeholder='Summa' onChange={(e) => setSumma(e.target.value)} value={summa} required />
                    <Select style={{ paddingRight: "10px" }} onChange={(e) => setType(e.target.value)}>
                            <option value={0}>Azolik badali uchun qarz</option>
                            <option value={1}>Daromad solig'i uchun qarz</option>
                    </Select>
                    <Button>Qo'shish</Button>
                </Form> : null}
            <TitleContainer>
                <Title></Title>
                <DateInput type="date" onChange={(e) => DateStartFilter(e.target.value)} />
                <DateInput type="date" onChange={(e) => DateEndFilter(e.target.value)} />
            </TitleContainer>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TR>
                            <TH>№</TH>
                            <TH>Summa</TH>
                            <TH>To'lov turi</TH>
                            <TH>Sana</TH>
                            <TH>Xodim</TH>
                        </TR>
                    </TableHead>
                    <TableBody>
                        {payments?.result?.map((dat, index) => (
                            <TR>
                                <TD>{index + 1}</TD>
                                <TD>{dat.summa}</TD>
                                <TD>{dat.status == 0? 'Azolik badali uchun qarz': `Daromad solig'i uchun qarz`}</TD>
                                <TD>{dat.date}</TD>
                                <TD>{dat.xodim?.name}</TD>
                                <TD>{dat.xodim != undefined && permission?.includes(28)?(<PrintButton bg="red" onClick={() => DeleteQarzdorlik(dat.id)} key={index}>
                                    <FaTrash size={18} />
                                </PrintButton>):null}</TD>
                            </TR>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationComponent
                activePage={payments?.pagination?.current_page}
                items_count={payments?.pagination?.items_count}
                range_display={5}
                loadData={loadData}
                items_per_page={payments?.pagination?.number_of_object}
            />
            <div style={{ display: "none" }}>
                <Print ref={componentRef} printPayment={printPayment} />

            </div>
        </Container>
    );
}

export default DebitTab;