import React from 'react';
import { FaCar, FaDollarSign, FaMoneyBillAlt, FaUsers } from 'react-icons/fa';
import { Card, CardBody, CardContainer, CardIcon, CardSumma, Container, Subtitle, Text } from './styles';


const Widgets = ({widget, permission}) => {
    return (
        <Container>
            <CardContainer>
                <Card>
                    <CardBody>
                        <CardIcon bg="#387dfc">
                            <FaMoneyBillAlt size={24} color="white"/>
                        </CardIcon>
                        <CardSumma>
                            <Subtitle>Kunlik tushum</Subtitle>
                            <Text>{permission.includes(26)? widget.day_summa: "*"} so'm</Text>
                        </CardSumma>
                        
                        
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardIcon bg="#38cffc">
                            <FaDollarSign size={24} color="white"/>
                        </CardIcon>
                        <CardSumma>
                            <Subtitle>Oylik tushum</Subtitle>
                            <Text>{permission.includes(26)? widget.month_summa: "*"} so'm</Text>
                        </CardSumma>
                        
                        
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardIcon bg="#b138fc">
                            <FaUsers size={24} color="white"/>
                        </CardIcon>
                        <CardSumma>
                            <Subtitle>Haydovchilar</Subtitle>
                            <Text>{widget.driver} ta</Text>
                        </CardSumma>
                        
                        
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardIcon bg="#fcbe38">
                            <FaCar size={24} color="white"/>
                        </CardIcon>
                        <CardSumma>
                            <Subtitle>Mashinalar</Subtitle>
                            <Text>{widget.car} ta</Text>
                        </CardSumma>
                        
                        
                    </CardBody>
                </Card>
            </CardContainer>
        </Container>
    );
}
 
export default Widgets;